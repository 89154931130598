import React, { useEffect } from "react"

import "./styles.css"

import { useTranslation } from "gatsby-plugin-react-i18next"
import { Instagram } from "../svgs/instagram"
import { StaticImage } from "gatsby-plugin-image"
import carbonBadge from "../../config/carbon-badge"

export const Footer = () => {
  const { t } = useTranslation("footer")

  useEffect(() => carbonBadge())

  return (
    <div id="footer" className="footer">
      <div className="contact-and-reco">
        <div className="contact">
          <h1>{t("Contactez-nous !")}</h1>
          <div>
            <p className="bold uppercase">
              {t(
                "Vous souhaitez privatiser IKI pour un événement, rejoindre l'équipe ou placer une commande take-away ?"
              )}
            </p>
            <p>{t("N'hésitez pas à nous contacter !")}</p>
          </div>
          <a
            href="mailto:info@iki-brussels.be"
            target="_blank"
            rel="noreferrer"
          >
            <button>{t("Contacter par mail")}</button>
          </a>
        </div>
        <div className="reco-and-logo">
          <div className="reco">
            <p>{t("Recommandé par :")}</p>
            <a
              href="https://www.brusselskitchen.com/iki/bruxelles/restaurant"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage src="../../images/brands/bk.webp" alt="bk" />
            </a>
          </div>
          <StaticImage
            className="logo-footer"
            src="../../images/logo-eurasian.svg"
            alt="logo"
            layout="fullWidth"
          />
        </div>
      </div>
      <div className="bottom">
        <small>{t("© Tous droits réservés, IKI Brussels")}</small>
        <div className="socialNetworks">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/iki.brussels"
          >
            <StaticImage
              src="../../images/socialMedia/facebook.svg"
              alt="facebook"
              width={30}
              height={30}
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/iki.brussels/"
          >
            <Instagram />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/iki-salads/"
          >
            <StaticImage
              src="../../images/socialMedia/linkedin.svg"
              alt="linkedin"
              width={30}
              height={30}
            />
          </a>
        </div>
        <div id="wcb" class="carbonbadge wcb-d"></div>
        <small>
          {t("designé par ")}
          <a
            href="https://www.linkedin.com/in/charlie-pauvre/"
            target="_blank"
            rel="noreferrer"
          >
            {t("Charlie Pauvré")}
          </a>
          {t(" & ")}
          <a
            href="https://www.linkedin.com/in/valentine-michel-0a1ab7177/"
            target="_blank"
            rel="noreferrer"
          >
            {t("Valentine Michel")}
          </a>
        </small>
      </div>
    </div>
  )
}
