import React from "react"

import "./styles.css"

import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import { Link } from "gatsby"

export const Header = ({ pathname, showBackground }) => {
  const { languages, language, changeLanguage } = useI18next()
  const { t } = useTranslation("header")

  const getPathFromPage = pageName =>
    `${language === "en" ? "" : `/${language}`}/${pageName}`

  return (
    <div className={`header ${showBackground ? "showBackground" : ""}`}>
      <select
        name="language"
        value={language}
        onChange={e => changeLanguage(e.target.value)}
        className="bold"
      >
        {languages.map(l => (
          <option key={l} hidden={l === language} value={l}>
            {l}
          </option>
        ))}
      </select>
      <div className="pages">
        <Link to={"/"}>
          <h4
            className={`uppercase bold ${
              pathname === getPathFromPage("") ? "selected" : ""
            }`}
          >
            {t("Accueil")}
          </h4>
        </Link>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://drive.google.com/file/d/1y23WE-4b6Vrd7ZwgVbq66pFtGuYb7nj8/view?usp=sharing"
        >
          <h4
            className={`uppercase bold ${
              pathname === getPathFromPage("menu") ? "selected" : ""
            }`}
          >
            {t("Menu")}
          </h4>
        </a>
        <Link to={"/about"}>
          <h4
            className={`uppercase bold ${
              pathname === getPathFromPage("about") ? "selected" : ""
            }`}
          >
            {t("A propos")}
          </h4>
        </Link>
        <a href={"#footer"}>
          <h4 className="uppercase bold">{t("Contact")}</h4>
        </a>
      </div>
    </div>
  )
}
