import React from "react"

export const IkiBackground = () => (
  <svg
    width="1822"
    height="1116"
    viewBox="0 0 1822 1116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_182_1348)">
      <rect x="0" y="0" width="1821.22" height="1115.32" fill="#008381" />
      <path
        d="M10.4989 154.529C5.57293 144.344 -2.22779 132.633 -12.9033 119.395L-22.7439 107.193C-33.4194 93.956 -43.2125 83.8512 -52.123 76.8799L-15.8187 47.6013C-10.8927 57.7865 -3.09204 69.498 7.5835 82.7352L17.4241 94.9375C28.0997 108.175 37.8927 118.279 46.8032 125.251L10.4989 154.529Z"
        fill="#BCD7C4"
      />
      <path
        d="M74.4812 102.929C69.5553 92.7437 61.7548 81.0325 51.0791 67.7953L41.2385 55.5932C30.563 42.3558 20.7699 32.2512 11.8594 25.2795L48.1638 -3.99878C53.0897 6.1864 60.8904 17.8977 71.5662 31.1351L81.4064 43.3372C92.0821 56.5746 101.875 66.6794 110.785 73.6506L74.4812 102.929Z"
        fill="#BCD7C4"
      />
      <path
        d="M189.609 12.8291C185.329 16.2806 181.087 18.4201 176.883 19.2474C172.753 20.0153 169.002 19.7443 165.633 18.4346C162.262 17.1248 158.979 15.3182 155.781 13.0148C152.524 10.6373 149.482 8.14811 146.654 5.54706C143.839 2.81256 141.153 0.463149 138.595 -1.5012C136.036 -3.46554 133.467 -4.68863 130.886 -5.17047C128.379 -5.71181 126.019 -5.08985 123.805 -3.30459C122.919 -2.59049 122.287 -1.71403 121.907 -0.675234L127.453 6.20227C138.129 19.4397 147.922 29.5443 156.833 36.516L120.528 65.7942C115.602 55.6091 107.802 43.8978 97.1258 30.6604L87.2856 18.4583C76.6099 5.22091 66.8169 -4.88365 57.9062 -11.8553L94.2108 -41.1336C99.0766 -31.0224 106.281 -20.0507 115.824 -8.21835L116.092 -7.88553L119.191 -10.3849C122.806 -13.3008 124.323 -16.7815 123.74 -20.827C123.097 -24.9463 120.509 -29.8161 115.976 -35.4365C111.98 -40.3913 106.902 -44.0459 100.741 -46.4002L134.831 -73.8931C140.641 -70.7668 145.633 -66.6155 149.808 -61.4391C156.01 -53.748 157.955 -46.9565 155.644 -41.0646C153.273 -35.2466 146.258 -27.6364 134.599 -18.234L126.63 -11.8071L127.793 -10.365L128.125 -10.6328C130.929 -12.8942 135.518 -15.131 141.893 -17.3432C148.343 -19.6149 153.338 -22.179 156.88 -25.0354C164.628 -31.2839 167.331 -38.9558 164.989 -48.0513L196.747 -8.67191C197.801 -5.25046 197.628 -1.3882 196.227 2.91491C194.84 7.08453 192.634 10.3893 189.609 12.8291Z"
        fill="#BCD7C4"
      />
      <path
        d="M-2.49528 341.584C-7.42117 331.399 -15.2219 319.688 -25.8975 306.45L-35.738 294.248C-46.4136 281.011 -56.2066 270.906 -65.1172 263.934L-28.8128 234.656C-23.8869 244.841 -16.0861 256.552 -5.41061 269.79L4.42997 281.992C15.1055 295.23 24.8986 305.334 33.8091 312.306L-2.49528 341.584Z"
        fill="#BCD7C4"
      />
      <path
        d="M61.485 289.984C56.5593 279.799 48.7585 268.088 38.083 254.85L28.2424 242.649C17.5669 229.411 7.77381 219.307 -1.13672 212.335L35.1677 183.057C40.0936 193.242 47.8943 204.953 58.5699 218.19L68.4102 230.392C79.0859 243.63 88.8789 253.734 97.7898 260.706L61.485 289.984Z"
        fill="#BCD7C4"
      />
      <path
        d="M176.612 199.885C172.333 203.336 168.091 205.475 163.887 206.303C159.757 207.071 156.006 206.799 152.636 205.49C149.267 204.18 145.983 202.374 142.785 200.07C139.528 197.693 136.486 195.204 133.658 192.602C130.843 189.868 128.157 187.518 125.599 185.554C123.04 183.59 120.471 182.367 117.89 181.885C115.383 181.343 113.022 181.965 110.809 183.751C109.924 184.465 109.291 185.341 108.91 186.38L114.457 193.258C125.133 206.495 134.926 216.6 143.837 223.572L107.532 252.85C102.606 242.665 94.8054 230.953 84.1297 217.716L74.2894 205.513C63.6138 192.276 53.8207 182.171 44.9102 175.2L81.2146 145.922C86.081 156.033 93.2849 167.004 102.827 178.837L103.096 179.17L106.195 176.67C109.811 173.755 111.327 170.274 110.743 166.229C110.101 162.109 107.513 157.239 102.98 151.619C98.9843 146.664 93.9061 143.009 87.7451 140.655L121.836 113.162C127.645 116.288 132.637 120.44 136.812 125.617C143.014 133.307 144.96 140.099 142.648 145.991C140.276 151.809 133.262 159.419 121.603 168.821L113.634 175.248L114.796 176.691L115.129 176.422C117.932 174.161 122.522 171.924 128.897 169.712C135.347 167.44 140.342 164.876 143.884 162.02C151.632 155.771 154.335 148.1 151.992 139.004L183.751 178.384C184.805 181.805 184.631 185.667 183.231 189.97C181.844 194.14 179.638 197.445 176.612 199.885Z"
        fill="#BCD7C4"
      />
      <path
        d="M196.058 181.456C191.132 171.271 183.33 159.56 172.655 146.323L162.815 134.121C152.139 120.883 142.346 110.779 133.436 103.807L169.74 74.5284C174.666 84.7136 182.466 96.4248 193.142 109.663L202.983 121.865C213.658 135.102 223.451 145.207 232.362 152.178L196.058 181.456Z"
        fill="#BCD7C4"
      />
      <path
        d="M260.042 129.857C255.116 119.672 247.315 107.96 236.64 94.7227L226.799 82.521C216.123 69.2832 206.33 59.1789 197.42 52.2072L233.724 22.9289C238.65 33.1141 246.451 44.8254 257.126 58.0627L266.967 70.2647C277.643 83.5025 287.436 93.6067 296.346 100.579L260.042 129.857Z"
        fill="#BCD7C4"
      />
      <path
        d="M375.169 39.7568C370.89 43.2083 366.648 45.3477 362.444 46.1751C358.313 46.9429 354.563 46.672 351.193 45.3622C347.823 44.0525 344.539 42.2459 341.342 39.9424C338.085 37.565 335.042 35.0757 332.214 32.4747C329.4 29.7402 326.714 27.3908 324.156 25.4264C321.597 23.4621 319.027 22.239 316.446 21.7572C313.94 21.2158 311.579 21.8378 309.366 23.6231C308.48 24.3371 307.847 25.2136 307.467 26.2524L313.014 33.1299C323.69 46.3674 333.483 56.4719 342.393 63.4438L306.089 92.7221C301.163 82.5369 293.362 70.8257 282.687 57.5881L272.846 45.3859C262.171 32.1485 252.378 22.044 243.467 15.0723L279.771 -14.206C284.637 -4.0948 291.842 6.87696 301.384 18.7093L301.653 19.0421L304.752 16.5427C308.367 13.6268 309.883 10.1461 309.3 6.1007C308.658 1.98133 306.07 -2.88854 301.537 -8.50891C297.541 -13.4637 292.462 -17.1183 286.301 -19.4725L320.392 -46.9656C326.201 -43.8394 331.194 -39.688 335.368 -34.5113C341.571 -26.8203 343.516 -20.0288 341.205 -14.1369C338.833 -8.31893 331.819 -0.708781 320.159 8.69358L312.19 15.1205L313.353 16.5626L313.685 16.2948C316.489 14.0335 321.079 11.7967 327.454 9.58449C333.903 7.31272 338.899 4.74863 342.441 1.8922C350.189 -4.35621 352.891 -12.0282 350.549 -21.1237L382.308 18.2557C383.362 21.6772 383.188 25.5394 381.788 29.8425C380.401 34.0122 378.195 37.3169 375.169 39.7568Z"
        fill="#BCD7C4"
      />
      <path
        d="M394.614 21.3288C389.688 11.1437 381.888 -0.567593 371.212 -13.805L361.372 -26.0071C350.696 -39.2445 340.903 -49.349 331.992 -56.3208L368.297 -85.5988C373.223 -75.4141 381.023 -63.7023 391.699 -50.4652L401.539 -38.2631C412.215 -25.0257 422.008 -14.9212 430.919 -7.94945L394.614 21.3288Z"
        fill="#BCD7C4"
      />
      <path
        d="M-15.4875 528.64C-20.4134 518.455 -28.2141 506.743 -38.8897 493.506L-48.7303 481.304C-59.4058 468.066 -69.1991 457.962 -78.1094 450.99L-41.805 421.712C-36.8791 431.897 -29.0784 443.608 -18.4028 456.845L-8.56227 469.048C2.11329 482.285 11.9063 492.39 20.8169 499.361L-15.4875 528.64Z"
        fill="#BCD7C4"
      />
      <path
        d="M48.495 477.04C43.569 466.854 35.7683 455.143 25.0928 441.906L15.2521 429.704C4.57662 416.467 -5.21643 406.362 -14.127 399.39L22.1774 370.112C27.1033 380.297 34.904 392.008 45.5796 405.246L55.4202 417.447C66.0957 430.685 75.8887 440.789 84.7996 447.761L48.495 477.04Z"
        fill="#BCD7C4"
      />
      <path
        d="M163.622 386.94C159.343 390.391 155.101 392.531 150.896 393.358C146.767 394.126 143.016 393.855 139.646 392.545C136.276 391.235 132.992 389.429 129.795 387.125C126.538 384.748 123.496 382.259 120.667 379.658C117.853 376.923 115.167 374.574 112.608 372.609C110.05 370.645 107.481 369.422 104.9 368.94C102.393 368.399 100.032 369.021 97.8189 370.806C96.9331 371.52 96.3009 372.396 95.9203 373.436L101.467 380.313C112.143 393.551 121.936 403.655 130.846 410.627L94.5417 439.905C89.6157 429.72 81.8152 418.009 71.1395 404.771L61.2991 392.569C50.6235 379.331 40.8305 369.227 31.9199 362.255L68.2245 332.977C73.0909 343.088 80.2947 354.06 89.8373 365.892L90.1055 366.225L93.2047 363.726C96.8208 360.81 98.3369 357.329 97.7532 353.284C97.1105 349.164 94.5226 344.295 89.9901 338.674C85.9941 333.719 80.9159 330.065 74.755 327.71L108.846 300.218C114.655 303.344 119.647 307.495 123.821 312.672C130.024 320.363 131.97 327.154 129.658 333.046C127.286 338.864 120.271 346.475 108.613 355.877L100.644 362.304L101.806 363.746L102.138 363.478C104.942 361.217 109.532 358.98 115.907 356.768C122.357 354.496 127.352 351.931 130.894 349.075C138.641 342.827 141.345 335.155 139.002 326.059L170.76 365.439C171.815 368.86 171.641 372.722 170.241 377.026C168.854 381.195 166.648 384.5 163.622 386.94Z"
        fill="#BCD7C4"
      />
      <path
        d="M183.067 368.512C178.141 358.327 170.34 346.616 159.665 333.378L149.824 321.176C139.149 307.938 129.356 297.834 120.445 290.862L156.749 261.584C161.675 271.769 169.476 283.48 180.152 296.718L189.992 308.92C200.668 322.157 210.461 332.262 219.371 339.234L183.067 368.512Z"
        fill="#BCD7C4"
      />
      <path
        d="M247.052 316.912C242.126 306.727 234.325 295.016 223.65 281.778L213.809 269.576C203.133 256.339 193.34 246.234 184.43 239.263L220.734 209.984C225.66 220.17 233.461 231.881 244.136 245.118L253.977 257.32C264.653 270.558 274.446 280.662 283.356 287.634L247.052 316.912Z"
        fill="#BCD7C4"
      />
      <path
        d="M362.179 226.813C357.899 230.264 353.657 232.403 349.453 233.231C345.323 233.999 341.573 233.727 338.203 232.418C334.833 231.108 331.549 229.302 328.352 226.998C325.095 224.621 322.052 222.131 319.224 219.53C316.41 216.796 313.723 214.446 311.165 212.482C308.606 210.517 306.037 209.295 303.456 208.813C300.949 208.271 298.589 208.893 296.376 210.679C295.49 211.393 294.857 212.269 294.477 213.308L300.024 220.186C310.699 233.423 320.492 243.528 329.403 250.499L293.099 279.777C288.173 269.592 280.372 257.881 269.696 244.644L259.856 232.441C249.18 219.204 239.387 209.099 230.477 202.128L266.781 172.849C271.647 182.961 278.852 193.932 288.394 205.765L288.662 206.098L291.762 203.598C295.377 200.682 296.893 197.202 296.31 193.156C295.667 189.037 293.08 184.167 288.546 178.547C284.551 173.592 279.472 169.937 273.311 167.583L307.402 140.09C313.211 143.216 318.203 147.367 322.378 152.545C328.581 160.235 330.526 167.027 328.214 172.919C325.843 178.736 318.828 186.347 307.169 195.749L299.2 202.176L300.363 203.618L300.695 203.35C303.499 201.089 308.089 198.852 314.464 196.64C320.913 194.368 325.909 191.804 329.451 188.947C337.198 182.699 339.901 175.027 337.559 165.932L369.317 205.311C370.371 208.733 370.198 212.595 368.797 216.898C367.41 221.068 365.204 224.373 362.179 226.813Z"
        fill="#BCD7C4"
      />
      <path
        d="M381.622 208.384C376.696 198.199 368.895 186.488 358.22 173.251L348.38 161.048C337.704 147.811 327.911 137.707 319 130.735L355.305 101.456C360.231 111.642 368.031 123.353 378.707 136.591L388.547 148.792C399.223 162.03 409.016 172.134 417.927 179.106L381.622 208.384Z"
        fill="#BCD7C4"
      />
      <path
        d="M445.604 156.785C440.678 146.6 432.877 134.888 422.202 121.651L412.361 109.449C401.686 96.2112 391.893 86.107 382.982 79.1351L419.287 49.8567C424.213 60.0419 432.013 71.7532 442.689 84.9904L452.53 97.1927C463.205 110.43 472.998 120.535 481.909 127.506L445.604 156.785Z"
        fill="#BCD7C4"
      />
      <path
        d="M560.732 66.6849C556.452 70.1364 552.21 72.2756 548.006 73.103C543.876 73.8709 540.126 73.5996 536.755 72.2903C533.385 70.9804 530.101 69.1739 526.905 66.8702C523.648 64.4929 520.605 62.0038 517.776 59.4026C514.963 56.668 512.276 54.3186 509.717 52.3543C507.159 50.3899 504.59 49.1669 502.009 48.685C499.502 48.1437 497.142 48.7656 494.928 50.5509C494.043 51.265 493.41 52.1415 493.03 53.1803L498.576 60.0578C509.252 73.2952 519.045 83.4 527.955 90.3712L491.651 119.65C486.725 109.464 478.924 97.7531 468.249 84.5159L458.408 72.3137C447.733 59.0764 437.94 48.9718 429.029 42.0001L465.333 12.7218C470.2 22.833 477.404 33.8048 486.946 45.6371L487.215 45.9699L490.314 43.4706C493.93 40.5546 495.446 37.0739 494.863 33.0285C494.22 28.9092 491.632 24.0393 487.099 18.4189C483.103 13.4641 478.025 9.8096 471.864 7.45527L505.955 -20.0377C511.764 -16.9116 516.756 -12.7601 520.931 -7.58348C527.134 0.107517 529.079 6.89902 526.767 12.7909C524.396 18.6089 517.38 26.2191 505.722 35.6214L497.753 42.0484L498.916 43.4904L499.248 43.2226C502.052 40.9613 506.642 38.7245 513.017 36.5123C519.466 34.2406 524.461 31.6765 528.003 28.8201C535.751 22.5716 538.454 14.8996 536.111 5.80412L567.869 45.1836C568.924 48.605 568.751 52.4673 567.35 56.7704C565.963 60.94 563.757 64.2449 560.732 66.6849Z"
        fill="#BCD7C4"
      />
      <path
        d="M580.177 48.2567C575.251 38.0715 567.45 26.3602 556.775 13.1228L546.934 0.920692C536.259 -12.3167 526.465 -22.4212 517.555 -29.393L553.859 -58.6713C558.785 -48.4861 566.586 -36.7748 577.262 -23.5374L587.102 -11.3353C597.778 1.90209 607.571 12.0066 616.483 18.9783L580.177 48.2567Z"
        fill="#BCD7C4"
      />
      <path
        d="M-28.4778 715.697C-33.4037 705.508 -41.2044 693.796 -51.88 680.562L-61.7206 668.359C-72.3963 655.125 -82.1893 645.015 -91.0996 638.048L-54.7953 608.767C-49.8694 618.952 -42.0687 630.664 -31.3931 643.904L-21.5525 656.101C-10.8769 669.341 -1.08394 679.445 7.82659 686.418L-28.4778 715.697Z"
        fill="#BCD7C4"
      />
      <path
        d="M35.5047 664.093C30.5788 653.909 22.7781 642.198 12.1025 628.963L2.26195 616.761C-8.41358 603.522 -18.2066 593.417 -27.1172 586.446L9.18722 557.167C14.1131 567.352 21.9139 579.064 32.5894 592.301L42.43 604.503C53.1055 617.743 62.8985 627.846 71.8088 634.819L35.5047 664.093Z"
        fill="#BCD7C4"
      />
      <path
        d="M150.63 573.995C146.351 577.447 142.108 579.586 137.904 580.414C133.774 581.181 130.024 580.911 126.654 579.601C123.284 578.291 120 576.484 116.803 574.181C113.546 571.803 110.503 569.314 107.675 566.713C104.861 563.979 102.175 561.629 99.6162 559.665C97.0578 557.701 94.4883 556.477 91.9072 555.996C89.4003 555.454 87.0402 556.077 84.8267 557.862C83.9409 558.575 83.3087 559.452 82.9281 560.491L88.4747 567.368C99.1503 580.606 108.943 590.71 117.854 597.682L81.5495 626.962C76.6235 616.773 68.823 605.064 58.1474 591.826L48.3068 579.625C37.6313 566.387 27.8383 556.283 18.9277 549.311L55.2321 520.033C60.0984 530.144 67.3026 541.116 76.8451 552.948L77.1133 553.281L80.2125 550.781C83.828 547.865 85.3448 544.384 84.761 540.339C84.1183 536.22 81.5304 531.35 76.9979 525.73C73.0019 520.775 67.9237 517.12 61.7622 514.766L95.8529 487.273C101.662 490.399 106.654 494.55 110.829 499.727C117.032 507.418 118.977 514.21 116.665 520.101C114.294 525.92 107.279 533.529 95.6202 542.932L87.6515 549.359L88.8141 550.801L89.1462 550.533C91.9501 548.272 96.5397 546.035 102.915 543.823C109.364 541.551 114.36 538.987 117.902 536.131C125.649 529.882 128.353 522.21 126.01 513.115L157.768 552.494C158.823 555.916 158.649 559.778 157.248 564.081C155.862 568.251 153.656 571.555 150.63 573.995Z"
        fill="#BCD7C4"
      />
      <path
        d="M170.077 555.567C165.151 545.382 157.35 533.671 146.674 520.433L136.834 508.231C126.158 494.994 116.365 484.889 107.455 477.918L143.759 448.64C148.685 458.824 156.486 470.536 167.161 483.773L177.002 495.975C187.677 509.213 197.47 519.317 206.381 526.289L170.077 555.567Z"
        fill="#BCD7C4"
      />
      <path
        d="M234.061 503.968C229.135 493.782 221.334 482.071 210.659 468.834L200.818 456.632C190.143 443.395 180.35 433.29 171.439 426.318L207.744 397.04C212.67 407.225 220.471 418.936 231.146 432.174L240.987 444.376C251.662 457.613 261.455 467.717 270.366 474.689L234.061 503.968Z"
        fill="#BCD7C4"
      />
      <path
        d="M349.187 413.868C344.907 417.319 340.665 419.458 336.461 420.286C332.331 421.054 328.581 420.783 325.21 419.473C321.84 418.163 318.557 416.357 315.36 414.053C312.103 411.676 309.06 409.187 306.231 406.586C303.418 403.851 300.731 401.501 298.172 399.537C295.614 397.573 293.045 396.35 290.464 395.868C287.957 395.327 285.597 395.949 283.384 397.734C282.498 398.448 281.865 399.324 281.485 400.363L287.032 407.241C297.707 420.479 307.5 430.583 316.41 437.555L280.106 466.833C275.18 456.648 267.38 444.936 256.704 431.699L246.863 419.497C236.188 406.259 226.395 396.155 217.484 389.183L253.789 359.905C258.655 370.016 265.859 380.988 275.402 392.82L275.67 393.153L278.769 390.653C282.385 387.738 283.901 384.257 283.318 380.211C282.675 376.092 280.087 371.223 275.554 365.602C271.559 360.647 266.48 356.993 260.319 354.638L294.41 327.146C300.219 330.272 305.211 334.423 309.386 339.599C315.589 347.291 317.534 354.082 315.222 359.974C312.851 365.792 305.836 373.402 294.177 382.804L286.208 389.232L287.371 390.674L287.703 390.406C290.507 388.145 295.097 385.908 301.472 383.696C307.921 381.424 312.917 378.859 316.458 376.003C324.206 369.755 326.909 362.083 324.566 352.987L356.325 392.366C357.379 395.788 357.206 399.65 355.805 403.954C354.418 408.123 352.212 411.428 349.187 413.868Z"
        fill="#BCD7C4"
      />
      <path
        d="M368.632 395.44C363.706 385.255 355.905 373.543 345.23 360.306L335.389 348.104C324.714 334.866 314.921 324.762 306.01 317.79L342.315 288.512C347.241 298.697 355.041 310.408 365.717 323.646L375.557 335.848C386.233 349.085 396.026 359.19 404.937 366.162L368.632 395.44Z"
        fill="#BCD7C4"
      />
      <path
        d="M432.614 343.84C427.688 333.655 419.887 321.943 409.211 308.706L399.371 296.504C388.695 283.266 378.902 273.162 369.992 266.19L406.296 236.912C411.222 247.097 419.023 258.808 429.698 272.045L439.539 284.248C450.214 297.485 460.007 307.59 468.918 314.562L432.614 343.84Z"
        fill="#BCD7C4"
      />
      <path
        d="M547.741 253.74C543.461 257.192 539.22 259.331 535.016 260.158C530.885 260.926 527.136 260.655 523.765 259.345C520.395 258.036 517.111 256.229 513.914 253.925C510.657 251.548 507.615 249.059 504.786 246.458C501.972 243.723 499.286 241.374 496.727 239.41C494.169 237.445 491.599 236.222 489.019 235.74C486.512 235.199 484.152 235.821 481.938 237.606C481.053 238.32 480.42 239.197 480.04 240.235L485.586 247.113C496.262 260.35 506.055 270.455 514.965 277.426L478.661 306.705C473.735 296.52 465.934 284.808 455.259 271.571L445.418 259.369C434.742 246.132 424.949 236.027 416.039 229.056L452.343 199.777C457.21 209.888 464.414 220.86 473.956 232.692L474.225 233.025L477.324 230.526C480.94 227.61 482.456 224.129 481.873 220.084C481.229 215.964 478.641 211.094 474.109 205.474C470.113 200.519 465.035 196.865 458.874 194.511L492.964 167.017C498.774 170.144 503.766 174.295 507.941 179.472C514.143 187.162 516.088 193.954 513.777 199.846C511.406 205.664 504.39 213.274 492.732 222.677L484.762 229.103L485.926 230.545L486.258 230.278C489.062 228.016 493.651 225.78 500.026 223.567C506.475 221.296 511.471 218.732 515.012 215.875C522.761 209.627 525.463 201.955 523.121 192.859L554.879 232.239C555.934 235.66 555.761 239.523 554.359 243.825C552.973 247.995 550.767 251.3 547.741 253.74Z"
        fill="#BCD7C4"
      />
      <path
        d="M567.184 235.312C562.259 225.126 554.458 213.415 543.782 200.178L533.941 187.976C523.266 174.739 513.473 164.634 504.562 157.662L540.867 128.384C545.793 138.569 553.594 150.28 564.269 163.518L574.11 175.72C584.785 188.957 594.578 199.062 603.489 206.033L567.184 235.312Z"
        fill="#BCD7C4"
      />
      <path
        d="M631.167 183.713C626.244 173.528 618.442 161.816 607.767 148.579L597.926 136.376C587.251 123.139 577.458 113.034 568.547 106.063L604.852 76.7849C609.778 86.9701 617.577 98.6813 628.251 111.918L638.097 124.121C648.772 137.358 658.562 147.463 667.475 154.435L631.167 183.713Z"
        fill="#BCD7C4"
      />
      <path
        d="M746.294 93.6128C742.016 97.0638 737.774 99.2036 733.57 100.031C729.439 100.799 725.688 100.528 722.318 99.2177C718.948 97.9084 715.664 96.1019 712.466 93.7982C709.207 91.4209 706.169 88.9312 703.339 86.3304C700.527 83.5958 697.839 81.2467 695.279 79.2824C692.72 77.3176 690.154 76.0948 687.57 75.613C685.065 75.0716 682.702 75.6934 680.492 77.479C679.602 78.1929 678.97 79.0694 678.589 80.108L684.138 86.986C694.813 100.223 704.609 110.327 713.516 117.299L677.214 146.577C672.285 136.392 664.49 124.681 653.809 111.444L643.97 99.2416C633.295 86.0045 623.505 75.8997 614.592 68.9278L650.894 39.6498C655.761 49.761 662.967 60.7327 672.506 72.5653L672.776 72.898L675.876 70.3985C679.492 67.4822 681.008 64.0018 680.425 59.9564C679.78 55.8371 677.196 50.9672 672.66 45.3469C668.664 40.3921 663.587 36.7375 657.425 34.3832L691.517 6.8902C697.323 10.0164 702.32 14.1678 706.494 19.3444C712.694 27.0354 714.639 33.8269 712.331 39.7188C709.956 45.5368 702.946 53.147 691.283 62.5495L683.316 68.9763L684.476 70.4182L684.807 70.1505C687.613 67.8892 692.204 65.6524 698.582 63.4402C705.027 61.1685 710.023 58.6044 713.565 55.748C721.312 49.4995 724.019 41.8276 721.674 32.7321L753.433 72.1117C754.489 75.5332 754.311 79.3954 752.911 83.6983C751.524 87.868 749.321 91.1729 746.294 93.6128Z"
        fill="#BCD7C4"
      />
      <path
        d="M765.74 75.1846C760.811 64.9993 753.009 53.2881 742.335 40.0507L732.495 27.8487C721.82 14.6112 712.03 4.50671 703.117 -2.46499L739.419 -31.7433C744.348 -21.5581 752.15 -9.84687 762.824 3.39057L772.664 15.5927C783.338 28.83 793.135 38.9346 802.041 45.9063L765.74 75.1846Z"
        fill="#BCD7C4"
      />
      <path
        d="M829.72 23.5849C824.797 13.3997 816.996 1.68843 806.321 -11.549L796.482 -23.7511C785.801 -36.9885 776.01 -47.0931 767.098 -54.0647L803.406 -83.343C808.328 -73.1577 816.13 -61.4465 826.805 -48.2092L836.644 -36.0071C847.325 -22.7697 857.115 -12.6651 866.028 -5.69342L829.72 23.5849Z"
        fill="#BCD7C4"
      />
      <path
        d="M22.5106 851.15C17.5847 840.967 9.78394 829.255 -0.891577 816.015L-10.7322 803.812C-21.4077 790.578 -31.2008 780.474 -40.1113 773.501L-3.80693 744.222C1.11899 754.405 8.91973 766.117 19.5953 779.357L29.4358 791.56C40.1114 804.794 49.9044 814.898 58.815 821.871L22.5106 851.15Z"
        fill="#BCD7C4"
      />
      <path
        d="M137.638 761.053C133.358 764.503 129.116 766.639 124.912 767.467C120.782 768.235 117.032 767.965 113.662 766.657C110.292 765.344 107.008 763.539 103.811 761.237C100.554 758.855 97.5109 756.369 94.683 753.767C91.8686 751.035 89.1825 748.684 86.624 746.72C84.0656 744.756 81.4961 743.534 78.915 743.049C76.4081 742.509 74.048 743.129 71.8345 744.916C70.9487 745.634 70.3165 746.505 69.9359 747.549L75.4825 754.424C86.1581 767.664 95.9511 777.768 104.862 784.734L68.5573 814.014C63.6313 803.831 55.8308 792.119 45.1553 778.885L35.3147 766.682C24.6391 753.442 14.8461 743.338 5.93555 736.365L42.2399 707.085C47.1062 717.201 54.3105 728.17 63.8529 740.005L64.1211 740.336L67.2204 737.838C70.8364 734.922 72.3526 731.442 71.7688 727.397C71.1261 723.272 68.5382 718.404 64.0058 712.782C60.0097 707.828 54.9313 704.176 48.7703 701.819L82.8607 674.325C88.6705 677.456 93.6622 681.605 97.8368 686.78C104.04 694.471 105.985 701.266 103.674 707.159C101.302 712.972 94.287 720.583 82.6286 729.987L74.6593 736.414L75.8219 737.856L76.154 737.586C78.958 735.327 83.5475 733.093 89.9227 730.877C96.3722 728.606 101.368 726.04 104.909 723.186C112.657 716.937 115.36 709.264 113.018 700.168L144.776 739.551C145.831 742.97 145.657 746.831 144.256 751.133C142.87 755.308 140.663 758.61 137.638 761.053Z"
        fill="#BCD7C4"
      />
      <path
        d="M157.083 742.62C152.157 732.436 144.356 720.724 133.68 707.49L123.84 695.287C113.164 682.047 103.371 671.944 94.4609 664.97L130.765 635.697C135.691 645.881 143.492 657.592 154.167 670.826L164.008 683.029C174.683 696.27 184.477 706.373 193.387 713.346L157.083 742.62Z"
        fill="#BCD7C4"
      />
      <path
        d="M221.067 691.021C216.141 680.838 208.341 669.126 197.665 655.886L187.824 643.689C177.149 630.449 167.356 620.345 158.445 613.373L194.749 584.095C199.675 594.28 207.477 605.991 218.152 619.228L227.992 631.431C238.668 644.665 248.461 654.775 257.371 661.742L221.067 691.021Z"
        fill="#BCD7C4"
      />
      <path
        d="M336.193 600.923C331.913 604.374 327.671 606.513 323.467 607.341C319.337 608.109 315.587 607.838 312.217 606.528C308.846 605.218 305.563 603.412 302.365 601.108C299.109 598.731 296.066 596.242 293.237 593.641C290.423 590.906 287.737 588.557 285.178 586.592C282.62 584.628 280.05 583.405 277.47 582.923C274.963 582.382 272.603 583.004 270.389 584.789C269.504 585.503 268.871 586.379 268.491 587.419L274.037 594.296C284.713 607.533 294.506 617.638 303.416 624.612L267.112 653.885C262.186 643.702 254.386 631.99 243.71 618.756L233.869 606.552C223.194 593.314 213.401 583.21 204.49 576.238L240.794 546.96C245.661 557.071 252.865 568.043 262.408 579.875L262.676 580.208L265.775 577.708C269.391 574.793 270.907 571.312 270.324 567.267C269.681 563.147 267.093 558.278 262.56 552.657C258.565 547.702 253.486 544.048 247.325 541.693L281.416 514.201C287.225 517.326 292.217 521.478 296.392 526.655C302.594 534.346 304.54 541.137 302.228 547.029C299.857 552.847 292.842 560.457 281.183 569.859L273.214 576.287L274.377 577.729L274.709 577.461C277.513 575.2 282.102 572.963 288.478 570.751C294.927 568.479 299.922 565.914 303.464 563.058C311.212 556.809 313.915 549.138 311.572 540.042L343.331 579.422C344.385 582.843 344.212 586.705 342.811 591.009C341.424 595.178 339.218 598.483 336.193 600.923Z"
        fill="#BCD7C4"
      />
      <path
        d="M355.64 582.495C350.714 572.31 342.913 560.598 332.237 547.361L322.397 535.158C311.721 521.921 301.928 511.817 293.018 504.845L329.322 475.567C334.248 485.752 342.049 497.463 352.724 510.7L362.565 522.903C373.24 536.14 383.033 546.245 391.944 553.217L355.64 582.495Z"
        fill="#BCD7C4"
      />
      <path
        d="M419.62 530.895C414.694 520.71 406.893 508.998 396.217 495.761L386.377 483.559C375.701 470.322 365.908 460.217 356.998 453.245L393.302 423.967C398.228 434.152 406.029 445.863 416.704 459.101L426.545 471.303C437.22 484.54 447.013 494.645 455.924 501.616L419.62 530.895Z"
        fill="#BCD7C4"
      />
      <path
        d="M534.747 440.795C530.467 444.247 526.226 446.386 522.022 447.213C517.891 447.981 514.141 447.71 510.771 446.401C507.401 445.091 504.117 443.284 500.92 440.981C497.663 438.603 494.62 436.114 491.792 433.513C488.978 430.779 486.291 428.429 483.733 426.465C481.175 424.5 478.605 423.278 476.025 422.796C473.518 422.254 471.158 422.876 468.943 424.661C468.058 425.376 467.426 426.252 467.046 427.291L472.592 434.168C483.268 447.406 493.061 457.51 501.971 464.482L465.667 493.76C460.741 483.575 452.94 471.864 442.265 458.627L432.424 446.424C421.748 433.187 411.955 423.082 403.045 416.111L439.349 386.832C444.216 396.944 451.42 407.915 460.962 419.748L461.231 420.08L464.33 417.581C467.945 414.665 469.462 411.185 468.878 407.139C468.235 403.02 465.647 398.15 461.115 392.529C457.119 387.575 452.041 383.92 445.88 381.566L479.97 354.073C485.78 357.199 490.772 361.351 494.946 366.527C501.149 374.218 503.094 381.01 500.783 386.902C498.411 392.72 491.396 400.33 479.738 409.732L471.768 416.159L472.932 417.601L473.264 417.333C476.068 415.072 480.657 412.835 487.032 410.623C493.481 408.351 498.477 405.787 502.019 402.931C509.767 396.682 512.469 389.01 510.127 379.914L541.885 419.294C542.94 422.715 542.767 426.578 541.365 430.881C539.979 435.051 537.772 438.355 534.747 440.795Z"
        fill="#BCD7C4"
      />
      <path
        d="M554.192 422.367C549.266 412.182 541.466 400.471 530.79 387.233L520.949 375.031C510.274 361.794 500.481 351.689 491.57 344.718L527.874 315.439C532.8 325.625 540.602 337.336 551.277 350.573L561.117 362.775C571.793 376.013 581.586 386.117 590.496 393.089L554.192 422.367Z"
        fill="#BCD7C4"
      />
      <path
        d="M618.178 370.767C613.249 360.582 605.448 348.871 594.773 335.634L584.932 323.431C574.257 310.194 564.464 300.089 555.553 293.118L591.858 263.839C596.783 274.024 604.584 285.736 615.262 298.973L625.102 311.175C635.776 324.413 645.567 334.517 654.479 341.489L618.178 370.767Z"
        fill="#BCD7C4"
      />
      <path
        d="M733.301 280.667C729.023 284.119 724.781 286.258 720.576 287.085C716.445 287.853 712.695 287.583 709.325 286.273C705.955 284.963 702.671 283.156 699.473 280.853C696.22 278.475 693.175 275.986 690.345 273.385C687.534 270.651 684.846 268.301 682.286 266.337C679.726 264.373 677.16 263.149 674.576 262.667C672.072 262.127 669.715 262.748 667.499 264.533C666.615 265.248 665.983 266.124 665.602 267.163L671.145 274.04C681.819 287.278 691.616 297.382 700.523 304.354L664.221 333.632C659.298 323.447 651.496 311.736 640.822 298.498L630.976 286.297C620.302 273.059 610.51 262.955 601.6 255.983L637.906 226.704C642.768 236.815 649.974 247.788 659.519 259.62L659.783 259.952L662.883 257.453C666.498 254.537 668.014 251.057 667.431 247.011C666.793 242.892 664.203 238.022 659.666 232.401C655.677 227.447 650.594 223.793 644.431 221.438L678.523 193.945C684.336 197.071 689.327 201.223 693.501 206.399C699.706 214.09 701.646 220.882 699.338 226.774C696.969 232.592 689.953 240.202 678.29 249.604L670.322 256.031L671.489 257.473L671.82 257.206C674.619 254.944 679.211 252.707 685.588 250.495C692.033 248.223 697.03 245.659 700.572 242.803C708.318 236.555 711.025 228.882 708.68 219.787L740.44 259.166C741.496 262.588 741.324 266.45 739.918 270.753C738.531 274.922 736.327 278.227 733.301 280.667Z"
        fill="#BCD7C4"
      />
      <path
        d="M752.746 262.24C747.817 252.054 740.021 240.343 729.34 227.105L719.501 214.904C708.826 201.666 699.036 191.562 690.123 184.59L726.431 155.312C731.354 165.497 739.155 177.208 749.83 190.445L759.67 202.647C770.344 215.885 780.141 225.989 789.047 232.961L752.746 262.24Z"
        fill="#BCD7C4"
      />
      <path
        d="M816.726 210.64C811.803 200.455 804.001 188.744 793.327 175.506L783.487 163.304C772.813 150.066 763.016 139.962 754.109 132.99L790.411 103.712C795.334 113.897 803.136 125.609 813.81 138.846L823.656 151.048C834.33 164.286 844.121 174.39 853.034 181.362L816.726 210.64Z"
        fill="#BCD7C4"
      />
      <path
        d="M931.855 120.54C927.577 123.992 923.335 126.131 919.13 126.958C914.999 127.726 911.249 127.456 907.879 126.146C904.509 124.836 901.225 123.029 898.027 120.726C894.774 118.348 891.729 115.859 888.899 113.258C886.088 110.524 883.399 108.174 880.84 106.21C878.28 104.246 875.714 103.022 873.13 102.54C870.626 101.999 868.263 102.621 866.053 104.406C865.169 105.12 864.53 105.997 864.156 107.035L869.699 113.913C880.373 127.15 890.17 137.255 899.077 144.227L862.775 173.505C857.846 163.32 850.05 151.609 839.37 138.371L829.53 126.169C818.856 112.932 809.065 102.827 800.152 95.8557L836.454 66.5774C841.322 76.6884 848.528 87.6599 858.067 99.4926L858.337 99.8253L861.437 97.3258C865.052 94.4102 866.568 90.9292 865.985 86.884C865.341 82.7646 862.757 77.8946 858.22 72.2744C854.224 67.3195 849.148 63.6648 842.985 61.3107L877.077 33.8177C882.89 36.9438 887.88 41.0953 892.054 46.2719C898.254 53.9629 900.2 60.7544 897.892 66.6462C895.516 72.464 888.507 80.0742 876.844 89.4768L868.876 95.9036L870.036 97.3461L870.374 97.0779C873.173 94.8165 877.765 92.5797 884.142 90.3675C890.587 88.0957 895.584 85.5318 899.126 82.6756C906.872 76.4269 909.579 68.7553 907.234 59.6595L938.994 99.039C940.05 102.461 939.872 106.323 938.472 110.626C937.085 114.795 934.881 118.1 931.855 120.54Z"
        fill="#BCD7C4"
      />
      <path
        d="M951.298 102.112C946.369 91.9267 938.574 80.2155 927.893 66.9783L918.054 54.7762C907.379 41.5387 897.589 31.4342 888.676 24.4625L924.977 -4.8158C929.907 5.36937 937.708 17.0806 948.383 30.3181L958.222 42.5201C968.897 55.7575 978.693 65.8624 987.6 72.8336L951.298 102.112Z"
        fill="#BCD7C4"
      />
      <path
        d="M1015.28 50.5124C1010.36 40.3272 1002.56 28.616 991.882 15.3785L982.042 3.17645C971.361 -10.0609 961.571 -20.1655 952.658 -27.1372L988.966 -56.4155C993.889 -46.2304 1001.69 -34.519 1012.37 -21.2816L1022.2 -9.07956C1032.89 4.15786 1042.68 14.2624 1051.59 21.2341L1015.28 50.5124Z"
        fill="#BCD7C4"
      />
      <path
        d="M1130.41 -39.5876C1126.13 -36.1361 1121.89 -33.9967 1117.69 -33.1693C1113.55 -32.4015 1109.8 -32.6724 1106.43 -33.9822C1103.06 -35.2919 1099.78 -37.0985 1096.58 -39.402C1093.32 -41.7794 1090.28 -44.2687 1087.45 -46.8697C1084.64 -49.6042 1081.95 -51.9536 1079.39 -53.918C1076.83 -55.8823 1074.27 -57.1054 1071.68 -57.5873C1069.18 -58.1286 1066.82 -57.5066 1064.61 -55.7214C1063.72 -55.0072 1063.09 -54.1308 1062.7 -53.092L1068.25 -46.2145C1078.93 -32.9771 1088.72 -22.8725 1097.63 -15.9008L1061.33 13.3775C1056.4 3.19232 1048.6 -8.51895 1037.92 -21.7564L1028.08 -33.9584C1017.41 -47.1959 1007.62 -57.3004 998.707 -64.272L1035.01 -93.5503C1039.88 -83.4393 1047.08 -72.4672 1056.62 -60.6351L1056.89 -60.3023L1059.99 -62.8018C1063.61 -65.7175 1065.12 -69.1985 1064.54 -73.2436C1063.9 -77.363 1061.31 -82.2331 1056.78 -87.8533C1052.78 -92.8081 1047.7 -96.4629 1041.54 -98.8169L1075.63 -126.31C1081.44 -123.184 1086.44 -119.033 1090.61 -113.856C1096.81 -106.165 1098.75 -99.373 1096.45 -93.4815C1094.07 -87.6637 1087.06 -80.0534 1075.4 -70.6508L1067.43 -64.2241L1068.59 -62.7816L1068.92 -63.0498C1071.73 -65.3112 1076.32 -67.548 1082.7 -69.7602C1089.14 -72.032 1094.14 -74.5959 1097.68 -77.452C1105.43 -83.7008 1108.13 -91.3724 1105.79 -100.468L1137.55 -61.0887C1138.6 -57.6672 1138.43 -53.805 1137.03 -49.5019C1135.64 -45.3322 1133.44 -42.0275 1130.41 -39.5876Z"
        fill="#BCD7C4"
      />
      <path
        d="M9.51839 1038.21C4.59246 1028.02 -3.20826 1016.31 -13.8838 1003.07L-23.7244 990.87C-34.3999 977.629 -44.193 967.526 -53.1035 960.559L-16.7992 931.279C-11.8732 941.463 -4.07251 953.174 6.60302 966.409L16.4436 978.611C27.1192 991.852 36.9122 1001.96 45.8227 1008.93L9.51839 1038.21Z"
        fill="#BCD7C4"
      />
      <path
        d="M124.646 948.104C120.366 951.56 116.124 953.696 111.92 954.525C107.79 955.292 104.04 955.022 100.67 953.708C97.2992 952.401 94.0159 950.596 90.8184 948.288C87.5615 945.913 84.5187 943.427 81.6908 940.824C78.8764 938.087 76.1903 935.742 73.6319 933.777C71.0735 931.813 68.504 930.586 65.9229 930.107C63.416 929.567 61.056 930.187 58.8423 931.973C57.9569 932.685 57.3241 933.563 56.944 934.6L62.4903 941.481C73.166 954.715 82.959 964.819 91.8699 971.792L55.5653 1001.07C50.6394 990.888 42.8386 979.176 32.1631 965.936L22.3225 953.733C11.647 940.499 1.85392 930.395 -7.05664 923.422L29.2478 894.143C34.1141 904.252 41.3184 915.228 50.8607 927.056L51.1291 927.394L54.2283 924.889C57.844 921.974 59.3601 918.493 58.7768 914.448C58.1339 910.329 55.5461 905.462 51.0134 899.839C47.0176 894.885 41.9391 891.233 35.7781 888.876L69.8685 861.383C75.6778 864.507 80.67 868.663 84.8447 873.837C91.0474 881.529 92.9926 888.318 90.6809 894.21C88.3097 900.029 81.2949 907.641 69.6359 917.045L61.6672 923.471L62.8298 924.914L63.1619 924.644C65.9658 922.385 70.5554 920.144 76.9306 917.935C83.3801 915.664 88.3754 913.098 91.9172 910.243C99.6649 903.995 102.368 896.322 100.026 887.225L131.784 926.602C132.839 930.027 132.665 933.888 131.264 938.191C129.877 942.359 127.671 945.667 124.646 948.104Z"
        fill="#BCD7C4"
      />
      <path
        d="M144.09 929.677C139.165 919.494 131.364 907.782 120.688 894.542L110.848 882.339C100.172 869.105 90.3791 859.001 81.4688 852.028L117.773 822.749C122.699 832.932 130.499 844.644 141.175 857.884L151.016 870.087C161.691 883.321 171.484 893.431 180.395 900.398L144.09 929.677Z"
        fill="#BCD7C4"
      />
      <path
        d="M208.075 878.079C203.149 867.896 195.348 856.184 184.673 842.944L174.832 830.741C164.156 817.507 154.363 807.403 145.453 800.43L181.757 771.15C186.683 781.334 194.484 793.046 205.159 806.286L215 818.489C225.676 831.723 235.469 841.826 244.379 848.799L208.075 878.079Z"
        fill="#BCD7C4"
      />
      <path
        d="M323.203 787.975C318.922 791.431 314.681 793.567 310.477 794.396C306.346 795.163 302.596 794.893 299.226 793.586C295.856 792.272 292.573 790.467 289.375 788.166C286.118 785.784 283.076 783.298 280.247 780.695C277.433 777.964 274.747 775.613 272.188 773.649C269.63 771.684 267.06 770.463 264.48 769.978C261.973 769.438 259.613 770.058 257.399 771.844C256.513 772.556 255.881 773.434 255.501 774.471L261.047 781.352C271.723 794.586 281.516 804.696 290.426 811.663L254.122 840.942C249.196 830.759 241.396 819.047 230.72 805.807L220.879 793.61C210.203 780.37 200.41 770.266 191.5 763.293L227.804 734.014C232.671 744.124 239.875 755.099 249.418 766.933L249.686 767.265L252.785 764.767C256.401 761.851 257.917 758.37 257.334 754.319C256.69 750.2 254.103 745.333 249.57 739.71C245.574 734.757 240.496 731.104 234.335 728.747L268.425 701.254C274.235 704.384 279.227 708.534 283.402 713.708C289.604 721.4 291.549 728.195 289.238 734.087C286.867 739.9 279.852 747.512 268.193 756.916L260.223 763.342L261.387 764.785L261.719 764.515C264.523 762.256 269.112 760.016 275.487 757.806C281.936 755.535 286.932 752.969 290.474 750.114C298.222 743.866 300.924 736.193 298.582 727.096L330.341 766.479C331.395 769.898 331.222 773.759 329.821 778.062C328.434 782.236 326.228 785.538 323.203 787.975Z"
        fill="#BCD7C4"
      />
      <path
        d="M342.647 769.548C337.721 759.365 329.921 747.653 319.245 734.419L309.405 722.216C298.729 708.976 288.936 698.872 280.025 691.899L316.33 662.62C321.256 672.809 329.057 684.521 339.732 697.755L349.573 709.958C360.248 723.198 370.041 733.302 378.952 740.275L342.647 769.548Z"
        fill="#BCD7C4"
      />
      <path
        d="M406.629 717.95C401.704 707.767 393.903 696.055 383.227 682.815L373.387 670.612C362.711 657.378 352.918 647.274 344.008 640.301L380.312 611.023C385.238 621.205 393.038 632.917 403.714 646.157L413.555 658.36C424.23 671.594 434.023 681.704 442.934 688.671L406.629 717.95Z"
        fill="#BCD7C4"
      />
      <path
        d="M521.757 627.852C517.477 631.302 513.236 633.444 509.031 634.267C504.901 635.034 501.151 634.764 497.781 633.457C494.411 632.143 491.127 630.338 487.93 628.037C484.673 625.661 481.63 623.169 478.802 620.566C475.987 617.835 473.301 615.484 470.743 613.52C468.184 611.556 465.615 610.333 463.034 609.851C460.527 609.309 458.167 609.931 455.953 611.717C455.068 612.431 454.435 613.307 454.055 614.348L459.602 621.223C470.278 634.463 480.071 644.567 488.981 651.54L452.677 680.813C447.751 670.63 439.95 658.918 429.275 645.684L419.434 633.481C408.758 620.241 398.965 610.137 390.055 603.166L426.359 573.888C431.225 583.999 438.43 594.97 447.972 606.803L448.241 607.136L451.339 604.636C454.955 601.721 456.471 598.24 455.888 594.194C455.245 590.075 452.657 585.205 448.125 579.585C444.129 574.63 439.05 570.975 432.889 568.621L466.98 541.128C472.789 544.254 477.781 548.405 481.956 553.582C488.158 561.273 490.104 568.064 487.792 573.957C485.421 579.774 478.406 587.385 466.747 596.787L458.778 603.214L459.941 604.657L460.273 604.388C463.077 602.127 467.667 599.89 474.042 597.678C480.491 595.406 485.486 592.842 489.028 589.986C496.776 583.737 499.479 576.066 497.137 566.97L528.895 606.349C529.949 609.771 529.776 613.633 528.375 617.939C526.988 622.107 524.782 625.409 521.757 627.852Z"
        fill="#BCD7C4"
      />
      <path
        d="M541.2 609.422C536.274 599.237 528.474 587.526 517.798 574.289L507.957 562.087C497.281 548.849 487.488 538.745 478.578 531.773L514.882 502.494C519.808 512.68 527.609 524.391 538.284 537.629L548.125 549.831C558.801 563.068 568.594 573.173 577.504 580.144L541.2 609.422Z"
        fill="#BCD7C4"
      />
      <path
        d="M605.183 557.823C600.257 547.638 592.456 535.926 581.78 522.689L571.94 510.487C561.264 497.25 551.471 487.145 542.561 480.173L578.865 450.895C583.791 461.08 591.592 472.791 602.267 486.029L612.108 498.231C622.786 511.469 632.577 521.573 641.49 528.545L605.183 557.823Z"
        fill="#BCD7C4"
      />
      <path
        d="M720.312 467.723C716.027 471.175 711.786 473.314 707.587 474.141C703.456 474.909 699.706 474.638 696.336 473.328C692.966 472.019 689.682 470.212 686.484 467.908C683.224 465.531 680.186 463.042 677.356 460.441C674.539 457.707 671.856 455.357 669.297 453.393C666.737 451.428 664.165 450.205 661.587 449.723C659.083 449.182 656.719 449.804 654.51 451.589C653.62 452.304 652.987 453.18 652.607 454.219L658.156 461.096C668.83 474.333 678.621 484.438 687.534 491.41L651.232 520.688C646.303 510.503 638.501 498.792 627.827 485.554L617.987 473.352C607.311 460.115 597.518 450.01 588.607 443.039L624.911 413.76C629.779 423.871 636.985 434.843 646.524 446.676L646.794 447.008L649.894 444.509C653.509 441.593 655.025 438.113 654.442 434.067C653.798 429.948 651.213 425.077 646.677 419.457C642.681 414.502 637.605 410.848 631.442 408.494L665.534 381.001C671.341 384.127 676.337 388.279 680.511 393.455C686.711 401.146 688.657 407.938 686.343 413.829C683.973 419.648 676.957 427.258 665.301 436.66L657.333 443.087L658.493 444.529L658.825 444.261C661.63 442 666.221 439.763 672.593 437.551C679.044 435.279 684.041 432.715 687.583 429.859C695.329 423.61 698.03 415.938 695.691 406.842L727.451 446.222C728.5 449.643 728.328 453.506 726.929 457.808C725.542 461.978 723.338 465.283 720.312 467.723Z"
        fill="#BCD7C4"
      />
      <path
        d="M739.757 449.295C734.828 439.11 727.027 427.399 716.352 414.161L706.512 401.959C695.838 388.722 686.041 378.617 677.135 371.645L713.436 342.367C718.366 352.552 726.161 364.264 736.842 377.501L746.681 389.703C757.356 402.94 767.146 413.045 776.059 420.017L739.757 449.295Z"
        fill="#BCD7C4"
      />
      <path
        d="M803.738 397.696C798.815 387.511 791.013 375.799 780.333 362.562L770.493 350.36C759.819 337.122 750.028 327.018 741.115 320.046L777.423 290.768C782.346 300.953 790.148 312.664 800.822 325.901L810.662 338.104C821.336 351.341 831.133 361.446 840.04 368.418L803.738 397.696Z"
        fill="#BCD7C4"
      />
      <path
        d="M918.867 307.596C914.582 311.047 910.341 313.187 906.136 314.014C902.011 314.782 898.261 314.511 894.891 313.201C891.521 311.891 888.237 310.085 885.039 307.781C881.779 305.404 878.735 302.915 875.911 300.313C873.094 297.579 870.411 295.23 867.852 293.265C865.292 291.301 862.72 290.078 860.142 289.596C857.638 289.055 855.274 289.676 853.058 291.462C852.174 292.176 851.542 293.052 851.162 294.091L856.711 300.969C867.385 314.206 877.176 324.311 886.088 331.282L849.787 360.56C844.858 350.375 837.056 338.664 826.381 325.427L816.542 313.225C805.867 299.987 796.071 289.883 787.164 282.911L823.466 253.632C828.333 263.744 835.54 274.716 845.079 286.548L845.349 286.881L848.448 284.382C852.064 281.466 853.58 277.985 852.997 273.94C852.352 269.82 849.762 264.95 845.232 259.33C841.236 254.375 836.16 250.72 829.997 248.366L864.089 220.873C869.896 223.999 874.886 228.151 879.066 233.328C885.266 241.018 887.212 247.81 884.898 253.702C882.528 259.52 875.512 267.13 863.856 276.533L855.888 282.959L857.048 284.401L857.38 284.134C860.185 281.872 864.776 279.635 871.148 277.423C877.599 275.151 882.596 272.587 886.137 269.731C893.884 263.483 896.585 255.81 894.246 246.715L925.999 286.095C927.055 289.516 926.883 293.378 925.484 297.681C924.096 301.851 921.887 305.156 918.867 307.596Z"
        fill="#BCD7C4"
      />
      <path
        d="M938.306 289.168C933.383 278.982 925.581 267.271 914.907 254.034L905.067 241.832C894.393 228.595 884.596 218.49 875.689 211.518L911.991 182.24C916.914 192.425 924.716 204.136 935.39 217.374L945.236 229.575C955.91 242.813 965.701 252.917 974.614 259.889L938.306 289.168Z"
        fill="#BCD7C4"
      />
      <path
        d="M1002.29 237.568C997.363 227.382 989.568 215.671 978.887 202.434L969.048 190.232C958.373 176.994 948.583 166.89 939.67 159.918L975.972 130.64C980.901 140.825 988.702 152.537 999.377 165.774L1009.22 177.976C1019.89 191.213 1029.69 201.318 1038.59 208.289L1002.29 237.568Z"
        fill="#BCD7C4"
      />
      <path
        d="M1117.41 147.468C1113.14 150.919 1108.89 153.059 1104.69 153.886C1100.56 154.654 1096.81 154.383 1093.44 153.073C1090.07 151.764 1086.78 149.957 1083.59 147.654C1080.33 145.276 1077.29 142.786 1074.46 140.186C1071.65 137.451 1068.96 135.102 1066.4 133.137C1063.84 131.173 1061.27 129.95 1058.69 129.468C1056.18 128.927 1053.83 129.549 1051.61 131.334C1050.73 132.048 1050.1 132.925 1049.71 133.963L1055.26 140.841C1065.94 154.078 1075.73 164.183 1084.64 171.154L1048.33 200.433C1043.41 190.248 1035.61 178.536 1024.93 165.299L1015.09 153.097C1004.42 139.86 994.624 129.755 985.711 122.783L1022.02 93.5048C1026.89 103.616 1034.09 114.588 1043.63 126.42L1043.9 126.753L1047 124.254C1050.62 121.338 1052.13 117.857 1051.55 113.811C1050.91 109.692 1048.32 104.823 1043.79 99.2023C1039.79 94.2475 1034.71 90.5928 1028.55 88.2382L1062.64 60.7454C1068.45 63.8717 1073.44 68.023 1077.61 73.1994C1083.82 80.8907 1085.76 87.682 1083.45 93.5742C1081.08 99.392 1074.07 107.002 1062.41 116.405L1054.44 122.832L1055.6 124.273L1055.93 124.006C1058.74 121.744 1063.32 119.508 1069.7 117.295C1076.15 115.024 1081.15 112.46 1084.69 109.603C1092.44 103.355 1095.14 95.6827 1092.8 86.587L1124.55 125.966C1125.61 129.388 1125.44 133.25 1124.04 137.554C1122.65 141.723 1120.44 145.028 1117.41 147.468Z"
        fill="#BCD7C4"
      />
      <path
        d="M1136.86 129.04C1131.94 118.855 1124.14 107.143 1113.46 93.9057L1103.62 81.7041C1092.94 68.4663 1083.15 58.362 1074.24 51.3902L1110.55 22.1119C1115.47 32.2971 1123.27 44.0084 1133.95 57.2458L1143.79 69.4478C1154.47 82.6856 1164.26 92.7898 1173.17 99.7616L1136.86 129.04Z"
        fill="#BCD7C4"
      />
      <path
        d="M1200.85 77.4404C1195.92 67.2551 1188.12 55.5439 1177.45 42.3065L1167.6 30.1044C1156.93 16.867 1147.14 6.76241 1138.22 -0.209276L1174.53 -29.4875C1179.45 -19.3024 1187.26 -7.59113 1197.93 5.64629L1207.77 17.8484C1218.45 31.0858 1228.24 41.1903 1237.15 48.1621L1200.85 77.4404Z"
        fill="#BCD7C4"
      />
      <path
        d="M1315.97 -12.6596C1311.7 -9.20813 1307.45 -7.0687 1303.25 -6.24133C1299.12 -5.47347 1295.37 -5.74442 1292 -7.05415C1288.63 -8.36393 1285.34 -10.1705 1282.15 -12.474C1278.89 -14.8515 1275.85 -17.3407 1273.02 -19.9417C1270.2 -22.6762 1267.52 -25.0256 1264.96 -26.9899C1262.4 -28.9543 1259.83 -30.1774 1257.25 -30.6592C1254.74 -31.2006 1252.38 -30.5786 1250.17 -28.7934C1249.28 -28.0792 1248.65 -27.2028 1248.27 -26.164L1253.82 -19.2865C1264.49 -6.04906 1274.28 4.05548 1283.2 11.0272L1246.89 40.3055C1241.97 30.1203 1234.16 18.409 1223.49 5.17163L1213.65 -7.03046C1202.97 -20.2679 1193.18 -30.3724 1184.27 -37.3441L1220.57 -66.6223C1225.44 -56.5112 1232.65 -45.5394 1242.19 -33.7071L1242.46 -33.3743L1245.56 -35.8737C1249.17 -38.7896 1250.69 -42.2703 1250.1 -46.3157C1249.46 -50.4351 1246.88 -55.305 1242.34 -60.9253C1238.34 -65.8802 1233.27 -69.5349 1227.1 -71.8889L1261.2 -99.3822C1267 -96.256 1272 -92.1047 1276.17 -86.9277C1282.37 -79.237 1284.32 -72.445 1282.01 -66.5535C1279.64 -60.7354 1272.62 -53.1252 1260.96 -43.7228L1253 -37.2958L1254.16 -35.8538L1254.49 -36.1216C1257.29 -38.3829 1261.88 -40.6197 1268.26 -42.832C1274.71 -45.1037 1279.7 -47.6678 1283.24 -50.5242C1290.99 -56.7726 1293.69 -64.4444 1291.35 -73.5401L1323.11 -34.1607C1324.16 -30.7393 1323.99 -26.8769 1322.59 -22.5739C1321.2 -18.4042 1319 -15.0995 1315.97 -12.6596Z"
        fill="#BCD7C4"
      />
      <path
        d="M111.656 1135.16C107.376 1138.61 103.134 1140.75 98.9297 1141.58C94.7999 1142.35 91.0494 1142.07 87.6795 1140.77C84.309 1139.46 81.0256 1137.65 77.8282 1135.35C74.5712 1132.97 71.5285 1130.48 68.7006 1127.88C65.8862 1125.14 63.2001 1122.79 60.6414 1120.83C58.083 1118.86 55.5135 1117.64 52.9329 1117.16C50.426 1116.62 48.0657 1117.24 45.852 1119.02C44.9666 1119.74 44.3338 1120.62 43.9538 1121.66L49.5002 1128.53C60.1758 1141.77 69.9688 1151.88 78.8797 1158.85L42.575 1188.13C37.6491 1177.94 29.8484 1166.23 19.1728 1152.99L9.33221 1140.79C-1.34331 1127.55 -11.1363 1117.45 -20.0469 1110.47L16.2575 1081.2C21.1238 1091.31 28.3281 1102.28 37.8705 1114.11L38.1389 1114.45L41.238 1111.95C44.8537 1109.03 46.3698 1105.55 45.7866 1101.51C45.1436 1097.39 42.5558 1092.51 38.0232 1086.9C34.0273 1081.94 28.9489 1078.28 22.7878 1075.93L56.8785 1048.44C62.6876 1051.56 67.6798 1055.71 71.8544 1060.89C78.0572 1068.59 80.0024 1075.38 77.6907 1081.27C75.3195 1087.09 68.3047 1094.69 56.6459 1104.1L48.6767 1110.52L49.8396 1111.97L50.1717 1111.7C52.9757 1109.44 57.5653 1107.2 63.9404 1104.99C70.3899 1102.71 75.3852 1100.16 78.9269 1097.29C86.6747 1091.05 89.3774 1083.37 87.0356 1074.28L118.794 1113.66C119.848 1117.08 119.675 1120.95 118.274 1125.25C116.887 1129.42 114.681 1132.72 111.656 1135.16Z"
        fill="#BCD7C4"
      />
      <path
        d="M131.1 1116.73C126.174 1106.55 118.373 1094.83 107.698 1081.6L97.8575 1069.4C87.1818 1056.16 77.3888 1046.05 68.4785 1039.09L104.783 1009.81C109.709 1019.99 117.509 1031.7 128.185 1044.94L138.026 1057.14C148.701 1070.38 158.494 1080.48 167.405 1087.46L131.1 1116.73Z"
        fill="#BCD7C4"
      />
      <path
        d="M195.083 1065.13C190.157 1054.95 182.356 1043.23 171.681 1030L161.84 1017.8C151.164 1004.56 141.371 994.454 132.461 987.481L168.765 958.208C173.691 968.391 181.492 980.103 192.167 993.337L202.008 1005.54C212.684 1018.78 222.477 1028.88 231.387 1035.86L195.083 1065.13Z"
        fill="#BCD7C4"
      />
      <path
        d="M310.208 975.033C305.928 978.483 301.687 980.625 297.483 981.454C293.352 982.221 289.602 981.951 286.232 980.637C282.862 979.33 279.579 977.525 276.381 975.217C273.124 972.842 270.081 970.349 267.253 967.753C264.439 965.015 261.752 962.664 259.194 960.7C256.636 958.736 254.066 957.514 251.486 957.036C248.979 956.489 246.619 957.115 244.405 958.902C243.519 959.614 242.886 960.491 242.506 961.529L248.053 968.404C258.729 981.644 268.522 991.747 277.432 998.721L241.128 1028C236.202 1017.81 228.401 1006.1 217.726 992.865L207.885 980.662C197.209 967.428 187.416 957.318 178.506 950.351L214.81 921.071C219.676 931.181 226.881 942.156 236.423 953.985L236.692 954.316L239.791 951.818C243.406 948.902 244.922 945.422 244.339 941.377C243.696 937.258 241.109 932.39 236.576 926.768C232.58 921.814 227.501 918.156 221.341 915.805L255.431 888.312C261.24 891.436 266.233 895.591 270.407 900.766C276.61 908.457 278.555 915.246 276.244 921.139C273.872 926.958 266.858 934.569 255.199 943.967L247.229 950.394L248.392 951.836L248.725 951.572C251.529 949.307 256.118 947.073 262.493 944.863C268.942 942.592 273.938 940.026 277.48 937.166C285.228 930.917 287.93 923.251 285.588 914.154L317.347 953.531C318.401 956.956 318.228 960.817 316.827 965.12C315.44 969.288 313.233 972.596 310.208 975.033Z"
        fill="#BCD7C4"
      />
      <path
        d="M329.655 956.606C324.729 946.422 316.929 934.711 306.253 921.47L296.413 909.268C285.737 896.033 275.944 885.93 267.033 878.957L303.338 849.677C308.264 859.861 316.064 871.572 326.74 884.813L336.58 897.016C347.256 910.25 357.049 920.353 365.96 927.326L329.655 956.606Z"
        fill="#BCD7C4"
      />
      <path
        d="M393.639 905.007C388.713 894.818 380.912 883.106 370.237 869.872L360.397 857.669C349.721 844.435 339.928 834.325 331.018 827.358L367.322 798.079C372.248 808.262 380.048 819.974 390.724 833.214L400.565 845.411C411.24 858.651 421.033 868.755 429.944 875.728L393.639 905.007Z"
        fill="#BCD7C4"
      />
      <path
        d="M508.765 814.904C504.485 818.36 500.243 820.496 496.039 821.325C491.909 822.092 488.158 821.822 484.788 820.508C481.419 819.201 478.135 817.396 474.937 815.094C471.681 812.713 468.638 810.227 465.81 807.624C462.995 804.886 460.309 802.541 457.751 800.577C455.192 798.613 452.623 797.385 450.042 796.907C447.535 796.366 445.175 796.986 442.961 798.773C442.076 799.485 441.443 800.362 441.063 801.4L446.61 808.281C457.285 821.515 467.078 831.618 475.989 838.591L439.684 867.871C434.759 857.688 426.957 845.976 416.282 832.736L406.441 820.533C395.766 807.299 385.973 797.195 377.062 790.222L413.367 760.942C418.233 771.052 425.438 782.027 434.979 793.856L435.248 794.193L438.347 791.689C441.963 788.773 443.479 785.293 442.896 781.248C442.253 777.129 439.665 772.261 435.132 766.639C431.136 761.685 426.058 758.033 419.897 755.676L453.988 728.182C459.797 731.307 464.789 735.462 468.964 740.637C475.166 748.328 477.112 755.117 474.8 761.01C472.429 766.829 465.414 774.44 453.755 783.844L445.786 790.271L446.949 791.714L447.281 791.443C450.085 789.185 454.675 786.944 461.05 784.734C467.499 782.463 472.494 779.897 476.036 777.043C483.784 770.794 486.487 763.121 484.145 754.025L515.903 793.402C516.957 796.827 516.784 800.688 515.383 804.991C513.996 809.159 511.79 812.467 508.765 814.904Z"
        fill="#BCD7C4"
      />
      <path
        d="M528.21 796.477C523.284 786.293 515.483 774.582 504.808 761.341L494.967 749.145C484.291 735.904 474.498 725.801 465.588 718.828L501.892 689.548C506.818 699.738 514.619 711.443 525.294 724.684L535.135 736.887C545.811 750.121 555.604 760.23 564.514 767.197L528.21 796.477Z"
        fill="#BCD7C4"
      />
      <path
        d="M592.192 744.879C587.266 734.695 579.466 722.983 568.79 709.743L558.95 697.54C548.274 684.306 538.481 674.203 529.57 667.229L565.875 637.95C570.801 648.133 578.602 659.845 589.277 673.085L599.117 685.288C609.793 698.522 619.589 708.626 628.496 715.599L592.192 744.879Z"
        fill="#BCD7C4"
      />
      <path
        d="M707.318 654.781C703.04 658.231 698.798 660.367 694.593 661.196C690.462 661.963 686.712 661.693 683.342 660.385C679.972 659.072 676.688 657.267 673.49 654.965C670.237 652.584 667.192 650.098 664.362 647.495C661.551 644.763 658.862 642.413 656.303 640.448C653.749 638.484 651.177 637.263 648.599 636.778C646.089 636.237 643.732 636.857 641.516 638.644C640.632 639.356 640 640.233 639.619 641.277L645.162 648.152C655.843 661.386 665.633 671.496 674.546 678.463L638.238 707.742C633.315 697.559 625.513 685.847 614.839 672.607L604.997 660.41C594.321 647.17 584.528 637.066 575.617 630.093L611.922 600.815C616.791 610.927 623.991 621.898 633.536 633.733L633.806 634.064L636.9 631.566C640.515 628.651 642.031 625.17 641.448 621.125C640.81 617 638.22 612.133 633.689 606.513C629.693 601.558 624.611 597.903 618.454 595.549L652.54 568.056C658.353 571.182 663.343 575.334 667.517 580.51C673.723 588.201 675.669 594.993 673.355 600.885C670.986 606.703 663.97 614.312 652.313 623.715L644.339 630.142L645.506 631.585L645.837 631.315C648.642 629.056 653.228 626.815 659.605 624.605C666.057 622.334 671.047 619.768 674.589 616.914C682.341 610.665 685.042 602.993 682.697 593.898L714.457 633.279C715.513 636.698 715.341 640.559 713.941 644.862C712.554 649.036 710.344 652.338 707.318 654.781Z"
        fill="#BCD7C4"
      />
      <path
        d="M726.763 636.348C721.84 626.164 714.039 614.453 703.364 601.216L693.518 589.014C682.844 575.777 673.053 565.673 664.141 558.701L700.448 529.422C705.371 539.608 713.173 551.319 723.848 564.556L733.687 576.758C744.368 589.996 754.158 600.1 763.071 607.072L726.763 636.348Z"
        fill="#BCD7C4"
      />
      <path
        d="M790.748 584.751C785.819 574.566 778.017 562.854 767.342 549.617L757.503 537.415C746.828 524.177 737.032 514.073 728.125 507.101L764.427 477.823C769.356 488.008 777.151 499.719 787.832 512.956L797.672 525.159C808.346 538.396 818.137 548.501 827.049 555.472L790.748 584.751Z"
        fill="#BCD7C4"
      />
      <path
        d="M905.873 494.651C901.594 498.102 897.353 500.242 893.148 501.069C889.017 501.837 885.266 501.566 881.896 500.256C878.527 498.946 875.243 497.14 872.045 494.836C868.791 492.459 865.747 489.97 862.917 487.368C860.106 484.634 857.417 482.285 854.857 480.32C852.304 478.356 849.732 477.133 847.154 476.651C844.643 476.11 842.286 476.731 840.07 478.517C839.186 479.231 838.554 480.107 838.174 481.146L843.716 488.024C854.391 501.261 864.188 511.366 873.1 518.337L836.793 547.616C831.87 537.431 824.068 525.719 813.393 512.482L803.548 500.28C792.873 487.043 783.083 476.938 774.17 469.966L810.478 440.688C815.339 450.799 822.546 461.771 832.091 473.603L832.355 473.936L835.454 471.437C839.07 468.521 840.586 465.04 840.003 460.995C839.364 456.875 836.774 452.005 832.244 446.385C828.248 441.43 823.166 437.776 817.009 435.422L851.095 407.928C856.908 411.054 861.898 415.206 866.072 420.383C872.278 428.073 874.224 434.865 871.91 440.757C869.54 446.575 862.524 454.185 850.861 463.588L842.894 470.014L844.06 471.456L844.392 471.189C847.197 468.927 851.782 466.69 858.16 464.478C864.605 462.206 869.602 459.643 873.143 456.786C880.896 450.538 883.597 442.866 881.252 433.77L913.011 473.15C914.067 476.571 913.895 480.433 912.49 484.736C911.102 488.906 908.899 492.211 905.873 494.651Z"
        fill="#BCD7C4"
      />
      <path
        d="M925.318 476.223C920.395 466.037 912.593 454.326 901.919 441.089L892.073 428.887C881.399 415.65 871.608 405.545 862.695 398.573L899.003 369.295C903.926 379.48 911.728 391.191 922.402 404.429L932.242 416.631C942.916 429.868 952.713 439.972 961.62 446.944L925.318 476.223Z"
        fill="#BCD7C4"
      />
      <path
        d="M989.296 424.623C984.373 414.438 976.572 402.727 965.897 389.489L956.052 377.287C945.377 364.049 935.587 353.945 926.674 346.973L962.982 317.695C967.904 327.88 975.706 339.591 986.381 352.829L996.22 365.031C1006.89 378.268 1016.69 388.373 1025.6 395.345L989.296 424.623Z"
        fill="#BCD7C4"
      />
      <path
        d="M1104.43 334.523C1100.14 337.974 1095.9 340.114 1091.7 340.941C1087.57 341.709 1083.82 341.438 1080.45 340.129C1077.08 338.819 1073.8 337.012 1070.6 334.709C1067.34 332.331 1064.3 329.842 1061.47 327.241C1058.65 324.506 1055.97 322.157 1053.41 320.193C1050.85 318.228 1048.28 317.005 1045.7 316.523C1043.2 315.982 1040.83 316.604 1038.62 318.389C1037.74 319.103 1037.1 319.98 1036.72 321.018L1042.27 327.896C1052.95 341.133 1062.74 351.238 1071.65 358.21L1035.35 387.488C1030.42 377.303 1022.62 365.591 1011.94 352.354L1002.1 340.152C991.428 326.915 981.631 316.81 972.725 309.838L1009.03 280.56C1013.89 290.671 1021.1 301.643 1030.64 313.476L1030.91 313.808L1034.01 311.309C1037.62 308.393 1039.14 304.912 1038.56 300.867C1037.91 296.748 1035.32 291.878 1030.79 286.257C1026.8 281.303 1021.72 277.648 1015.56 275.294L1049.65 247.801C1055.46 250.927 1060.45 255.078 1064.63 260.255C1070.83 267.946 1072.77 274.737 1070.46 280.629C1068.09 286.447 1061.07 294.057 1049.42 303.46L1041.45 309.887L1042.61 311.329L1042.94 311.061C1045.75 308.8 1050.34 306.563 1056.71 304.351C1063.16 302.079 1068.16 299.515 1071.7 296.658C1079.44 290.41 1082.15 282.738 1079.81 273.643L1111.57 313.022C1112.62 316.444 1112.44 320.306 1111.04 324.609C1109.66 328.778 1107.45 332.083 1104.43 334.523Z"
        fill="#BCD7C4"
      />
      <path
        d="M1123.87 316.095C1118.94 305.91 1111.14 294.198 1100.47 280.961L1090.63 268.759C1079.95 255.522 1070.15 245.417 1061.25 238.445L1097.55 209.167C1102.48 219.352 1110.27 231.063 1120.95 244.301L1130.79 256.503C1141.47 269.741 1151.26 279.845 1160.17 286.817L1123.87 316.095Z"
        fill="#BCD7C4"
      />
      <path
        d="M1187.85 264.495C1182.93 254.31 1175.13 242.599 1164.45 229.362L1154.61 217.16C1143.94 203.922 1134.14 193.818 1125.23 186.846L1161.54 157.568C1166.46 167.753 1174.26 179.464 1184.94 192.702L1194.78 204.903C1205.46 218.141 1215.25 228.245 1224.16 235.217L1187.85 264.495Z"
        fill="#BCD7C4"
      />
      <path
        d="M1302.98 174.395C1298.7 177.847 1294.46 179.986 1290.26 180.814C1286.12 181.582 1282.37 181.311 1279 180.001C1275.63 178.691 1272.35 176.885 1269.15 174.581C1265.89 172.204 1262.85 169.714 1260.02 167.114C1257.21 164.379 1254.52 162.029 1251.96 160.065C1249.41 158.101 1246.84 156.877 1244.26 156.396C1241.75 155.855 1239.39 156.477 1237.18 158.262C1236.29 158.976 1235.66 159.852 1235.27 160.891L1240.82 167.769C1251.5 181.006 1261.3 191.111 1270.2 198.082L1233.9 227.361C1228.97 217.176 1221.18 205.464 1210.49 192.227L1200.66 180.025C1189.98 166.787 1180.19 156.683 1171.28 149.711L1207.58 120.433C1212.45 130.544 1219.65 141.516 1229.19 153.348L1229.46 153.681L1232.56 151.181C1236.18 148.266 1237.69 144.785 1237.11 140.739C1236.47 136.62 1233.88 131.751 1229.35 126.13C1225.35 121.175 1220.27 117.521 1214.11 115.166L1248.2 87.6735C1254.01 90.7997 1259.01 94.951 1263.18 100.127C1269.38 107.819 1271.32 114.61 1269.02 120.502C1266.64 126.32 1259.63 133.93 1247.97 143.332L1240 149.76L1241.16 151.201L1241.49 150.934C1244.3 148.672 1248.89 146.436 1255.27 144.223C1261.71 141.952 1266.71 139.387 1270.25 136.531C1278 130.283 1280.7 122.611 1278.36 113.515L1310.12 152.894C1311.17 156.316 1311 160.178 1309.6 164.482C1308.21 168.651 1306.01 171.956 1302.98 174.395Z"
        fill="#BCD7C4"
      />
      <path
        d="M1322.43 155.968C1317.5 145.783 1309.7 134.071 1299.02 120.834L1289.18 108.632C1278.51 95.3942 1268.72 85.29 1259.8 78.3182L1296.11 49.0397C1301.04 59.2249 1308.84 70.9363 1319.51 84.1734L1329.35 96.3757C1340.03 109.613 1349.82 119.718 1358.73 126.69L1322.43 155.968Z"
        fill="#BCD7C4"
      />
      <path
        d="M1386.41 104.368C1381.48 94.1831 1373.68 82.4719 1363.01 69.2341L1353.17 57.0321C1342.49 43.7947 1332.7 33.6902 1323.79 26.7185L1360.09 -2.55981C1365.02 7.62534 1372.82 19.3367 1383.5 32.5741L1393.34 44.7762C1404.01 58.0136 1413.8 68.1182 1422.71 75.09L1386.41 104.368Z"
        fill="#BCD7C4"
      />
      <path
        d="M1501.53 14.2682C1497.26 17.7197 1493.01 19.8591 1488.81 20.6865C1484.68 21.4543 1480.93 21.1834 1477.56 19.8736C1474.19 18.5639 1470.9 16.7572 1467.71 14.4537C1464.45 12.0763 1461.41 9.58708 1458.58 6.98605C1455.77 4.25156 1453.08 1.90214 1450.52 -0.0622042C1447.97 -2.02655 1445.39 -3.24964 1442.82 -3.73147C1440.31 -4.27282 1437.95 -3.65086 1435.73 -1.8656C1434.85 -1.15149 1434.22 -0.275041 1433.84 0.763759L1439.38 7.6413C1450.06 20.8787 1459.85 30.9832 1468.76 37.9549L1432.45 67.2331C1427.53 57.0481 1419.73 45.3368 1409.06 32.0994L1399.21 19.8973C1388.54 6.65992 1378.74 -3.44466 1369.83 -10.4164L1406.14 -39.6947C1411 -29.5835 1418.21 -18.6117 1427.75 -6.77932L1428.02 -6.44657L1431.12 -8.94594C1434.73 -11.8619 1436.25 -15.3425 1435.66 -19.3879C1435.03 -23.5073 1432.44 -28.3772 1427.91 -33.9976C1423.91 -38.9523 1418.83 -42.6068 1412.67 -44.9612L1446.76 -72.4542C1452.57 -69.328 1457.56 -65.1767 1461.73 -60C1467.94 -52.309 1469.89 -45.5175 1467.57 -39.6255C1465.2 -33.8076 1458.19 -26.1974 1446.52 -16.7951L1438.56 -10.3681L1439.72 -8.92606L1440.05 -9.19381C1442.86 -11.4551 1447.44 -13.6919 1453.82 -15.9042C1460.27 -18.1759 1465.26 -20.74 1468.81 -23.5965C1476.56 -29.8448 1479.26 -37.5168 1476.91 -46.6124L1508.67 -7.23294C1509.73 -3.81147 1509.56 0.0507958 1508.15 4.3539C1506.76 8.52356 1504.56 11.8283 1501.53 14.2682Z"
        fill="#BCD7C4"
      />
      <path
        d="M297.216 1162.09C292.936 1165.54 288.695 1167.68 284.49 1168.5C280.36 1169.27 276.61 1169 273.24 1167.69C269.87 1166.39 266.586 1164.58 263.389 1162.27C260.132 1159.9 257.089 1157.41 254.261 1154.8C251.447 1152.07 248.76 1149.72 246.202 1147.76C243.643 1145.79 241.074 1144.57 238.493 1144.09C235.986 1143.55 233.626 1144.17 231.413 1145.95C230.527 1146.67 229.894 1147.54 229.514 1148.59L235.061 1155.46C245.737 1168.7 255.529 1178.8 264.44 1185.78L228.136 1215.05C223.21 1204.87 215.409 1193.16 204.733 1179.92L194.893 1167.72C184.218 1154.48 174.424 1144.38 165.514 1137.4L201.818 1108.12C206.684 1118.24 213.889 1129.21 223.431 1141.04L223.7 1141.37L226.799 1138.88C230.414 1135.96 231.93 1132.48 231.347 1128.43C230.704 1124.32 228.117 1119.44 223.584 1113.83C219.588 1108.87 214.509 1105.21 208.348 1102.86L242.439 1075.37C248.248 1078.49 253.241 1082.64 257.415 1087.82C263.618 1095.51 265.563 1102.3 263.251 1108.2C260.88 1114.02 253.865 1121.62 242.206 1131.02L234.237 1137.45L235.4 1138.89L235.732 1138.62C238.536 1136.36 243.126 1134.13 249.501 1131.91C255.95 1129.64 260.946 1127.08 264.488 1124.22C272.235 1117.97 274.938 1110.3 272.596 1101.2L304.354 1140.59C305.408 1144.01 305.235 1147.87 303.835 1152.18C302.447 1156.34 300.241 1159.65 297.216 1162.09Z"
        fill="#BCD7C4"
      />
      <path
        d="M316.661 1143.66C311.735 1133.47 303.935 1121.76 293.259 1108.53L283.419 1096.32C272.743 1083.08 262.95 1072.98 254.039 1066.01L290.344 1036.73C295.27 1046.92 303.07 1058.63 313.746 1071.86L323.586 1084.07C334.262 1097.31 344.055 1107.41 352.966 1114.38L316.661 1143.66Z"
        fill="#BCD7C4"
      />
      <path
        d="M380.645 1092.06C375.719 1081.88 367.918 1070.16 357.243 1056.93L347.402 1044.73C336.727 1031.49 326.934 1021.38 318.023 1014.41L354.328 985.13C359.254 995.32 367.054 1007.03 377.73 1020.27L387.571 1032.47C398.246 1045.71 408.039 1055.81 416.95 1062.79L380.645 1092.06Z"
        fill="#BCD7C4"
      />
      <path
        d="M495.772 1001.96C491.493 1005.41 487.251 1007.55 483.047 1008.38C478.917 1009.15 475.167 1008.87 471.796 1007.57C468.426 1006.26 465.142 1004.45 461.946 1002.15C458.689 999.77 455.646 997.278 452.817 994.681C450.003 991.944 447.317 989.593 444.758 987.629C442.2 985.664 439.631 984.443 437.05 983.964C434.543 983.418 432.183 984.044 429.969 985.83C429.084 986.542 428.451 987.42 428.071 988.457L433.617 995.332C444.293 1008.57 454.086 1018.68 462.996 1025.65L426.692 1054.93C421.766 1044.74 413.965 1033.03 403.29 1019.79L393.449 1007.59C382.774 994.35 372.981 984.246 364.07 977.273L400.374 948C405.241 958.11 412.445 969.079 421.987 980.913L422.256 981.245L425.355 978.746C428.971 975.831 430.487 972.35 429.904 968.305C429.261 964.186 426.673 959.313 422.14 953.696C418.144 948.743 413.066 945.084 406.905 942.733L440.996 915.24C446.805 918.364 451.797 922.514 455.972 927.694C462.174 935.386 464.12 942.175 461.808 948.067C459.437 953.887 452.421 961.498 440.763 970.896L432.794 977.322L433.957 978.765L434.289 978.501C437.093 976.236 441.683 974.002 448.058 971.786C454.507 969.515 459.502 966.955 463.044 964.094C470.792 957.846 473.495 950.173 471.152 941.082L502.911 980.459C503.965 983.884 503.792 987.745 502.391 992.048C501.004 996.216 498.798 999.518 495.772 1001.96Z"
        fill="#BCD7C4"
      />
      <path
        d="M515.218 983.534C510.292 973.345 502.491 961.639 491.816 948.399L481.975 936.196C471.299 922.962 461.506 912.852 452.596 905.885L488.9 876.606C493.826 886.789 501.627 898.501 512.302 911.741L522.143 923.938C532.819 937.178 542.612 947.282 551.522 954.255L515.218 983.534Z"
        fill="#BCD7C4"
      />
      <path
        d="M579.198 931.936C574.272 921.747 566.472 910.035 555.796 896.801L545.956 884.598C535.28 871.358 525.487 861.254 516.576 854.281L552.881 825.008C557.807 835.191 565.607 846.903 576.283 860.137L586.123 872.34C596.799 885.58 606.592 895.684 615.5 902.657L579.198 931.936Z"
        fill="#BCD7C4"
      />
      <path
        d="M694.329 841.832C690.044 845.282 685.803 847.424 681.598 848.253C677.467 849.02 673.723 848.75 670.347 847.437C666.977 846.129 663.699 844.325 660.501 842.017C657.241 839.641 654.197 837.155 651.373 834.552C648.556 831.815 645.867 829.47 643.314 827.506C640.754 825.542 638.182 824.314 635.604 823.835C633.093 823.295 630.736 823.915 628.52 825.701C627.636 826.413 627.004 827.291 626.624 828.328L632.173 835.209C642.847 848.443 652.638 858.547 661.55 865.52L625.243 894.8C620.32 884.616 612.519 872.904 601.843 859.664L592.003 847.461C581.327 834.227 571.534 824.117 562.623 817.151L598.928 787.871C603.794 797.981 610.998 808.956 620.541 820.784L620.811 821.116L623.911 818.618C627.526 815.702 629.042 812.221 628.459 808.176C627.815 804.058 625.224 799.19 620.694 793.567C616.698 788.614 611.619 784.955 605.458 782.604L639.551 755.111C645.358 758.235 650.348 762.391 654.522 767.566C660.728 775.257 662.674 782.046 660.36 787.939C657.99 793.758 650.974 801.369 639.318 810.773L631.344 817.2L632.51 818.642L632.842 818.372C635.647 816.107 640.238 813.873 646.61 811.663C653.061 809.392 658.058 806.826 661.599 803.972C669.346 797.723 672.047 790.05 669.708 780.953L701.461 820.33C702.517 823.755 702.345 827.616 700.946 831.919C699.559 836.087 697.349 839.396 694.329 841.832Z"
        fill="#BCD7C4"
      />
      <path
        d="M713.769 823.405C708.846 813.222 701.044 801.51 690.37 788.27L680.53 776.067C669.856 762.833 660.059 752.729 651.146 745.756L687.454 716.477C692.377 726.66 700.179 738.372 710.853 751.612L720.699 763.815C731.374 777.049 741.164 787.153 750.077 794.126L713.769 823.405Z"
        fill="#BCD7C4"
      />
      <path
        d="M777.755 771.807C772.826 761.618 765.025 749.912 754.35 736.672L744.511 724.469C733.836 711.235 724.046 701.125 715.133 694.158L751.435 664.879C756.364 675.062 764.165 686.774 774.84 700.014L784.679 712.217C795.354 725.451 805.15 735.555 814.057 742.528L777.755 771.807Z"
        fill="#BCD7C4"
      />
      <path
        d="M892.876 681.704C888.598 685.159 884.357 687.296 880.152 688.124C876.021 688.892 872.27 688.621 868.9 687.314C865.53 686 862.246 684.196 859.055 681.894C855.795 679.512 852.751 677.026 849.921 674.424C847.11 671.692 844.421 669.341 841.867 667.377C839.308 665.413 836.736 664.191 834.158 663.706C831.647 663.166 829.29 663.786 827.074 665.572C826.19 666.284 825.558 667.162 825.177 668.199L830.726 675.08C841.401 688.315 851.191 698.418 860.104 705.391L823.796 734.671C818.873 724.487 811.072 712.775 800.397 699.535L790.558 687.332C779.877 674.098 770.087 663.995 761.174 657.022L797.482 627.742C802.349 637.852 809.549 648.827 819.094 660.655L819.365 660.993L822.458 658.495C826.08 655.573 827.596 652.093 827.013 648.047C826.368 643.929 823.778 639.061 819.248 633.438C815.252 628.485 810.169 624.833 804.013 622.475L838.105 594.983C843.911 598.11 848.902 602.261 853.076 607.438C859.282 615.128 861.228 621.923 858.913 627.81C856.544 633.629 849.528 641.24 837.871 650.644L829.898 657.071L831.064 658.513L831.396 658.243C834.201 655.984 838.786 653.744 845.164 651.534C851.615 649.263 856.605 646.697 860.147 643.843C867.9 637.594 870.601 629.921 868.256 620.824L900.015 660.207C901.071 663.626 900.899 667.487 899.5 671.79C898.112 675.964 895.903 679.267 892.876 681.704Z"
        fill="#BCD7C4"
      />
      <path
        d="M912.324 663.276C907.401 653.093 899.599 641.381 888.925 628.141L879.085 615.944C868.404 602.705 858.614 592.6 849.701 585.628L886.009 556.35C890.932 566.535 898.734 578.247 909.408 591.484L919.248 603.686C929.928 616.926 939.719 627.03 948.632 633.997L912.324 663.276Z"
        fill="#BCD7C4"
      />
      <path
        d="M976.308 611.679C971.379 601.493 963.577 589.782 952.903 576.545L943.063 564.342C932.389 551.105 922.592 541 913.686 534.029L949.987 504.751C954.916 514.936 962.718 526.647 973.392 539.884L983.232 552.087C993.907 565.324 1003.7 575.429 1012.61 582.4L976.308 611.679Z"
        fill="#BCD7C4"
      />
      <path
        d="M1091.43 521.578C1087.15 525.03 1082.91 527.17 1078.71 527.997C1074.58 528.765 1070.82 528.494 1067.46 527.184C1064.09 525.874 1060.8 524.067 1057.6 521.764C1054.35 519.387 1051.31 516.897 1048.48 514.296C1045.66 511.562 1042.98 509.213 1040.42 507.248C1037.86 505.284 1035.29 504.061 1032.71 503.579C1030.2 503.038 1027.84 503.659 1025.63 505.445C1024.75 506.159 1024.11 507.035 1023.73 508.074L1029.28 514.952C1039.96 528.189 1049.75 538.293 1058.66 545.265L1022.35 574.543C1017.43 564.358 1009.63 552.647 998.952 539.41L989.112 527.208C978.432 513.97 968.641 503.866 959.729 496.894L996.036 467.616C1000.9 477.727 1008.1 488.699 1017.65 500.531L1017.91 500.864L1021.01 498.365C1024.63 495.448 1026.14 491.968 1025.56 487.922C1024.92 483.803 1022.33 478.933 1017.8 473.313C1013.81 468.358 1008.72 464.704 1002.57 462.349L1036.65 434.856C1042.47 437.982 1047.46 442.134 1051.63 447.31C1057.84 455.001 1059.78 461.793 1057.47 467.685C1055.1 473.503 1048.08 481.113 1036.43 490.516L1028.45 496.942L1029.62 498.384L1029.95 498.117C1032.76 495.855 1037.34 493.618 1043.72 491.406C1050.17 489.134 1055.16 486.57 1058.7 483.714C1066.45 477.466 1069.16 469.793 1066.81 460.698L1098.57 500.078C1099.63 503.499 1099.45 507.361 1098.05 511.664C1096.67 515.834 1094.46 519.139 1091.43 521.578Z"
        fill="#BCD7C4"
      />
      <path
        d="M1110.88 503.151C1105.95 492.965 1098.15 481.254 1087.48 468.017L1077.63 455.815C1066.96 442.577 1057.17 432.473 1048.25 425.501L1084.56 396.223C1089.48 406.408 1097.29 418.119 1107.96 431.357L1117.8 443.559C1128.48 456.796 1138.27 466.901 1147.18 473.872L1110.88 503.151Z"
        fill="#BCD7C4"
      />
      <path
        d="M1174.86 451.551C1169.93 441.365 1162.13 429.654 1151.46 416.417L1141.61 404.215C1130.94 390.978 1121.15 380.873 1112.23 373.901L1148.54 344.623C1153.47 354.808 1161.27 366.519 1171.94 379.757L1181.78 391.959C1192.46 405.196 1202.25 415.3 1211.16 422.272L1174.86 451.551Z"
        fill="#BCD7C4"
      />
      <path
        d="M1289.99 361.451C1285.71 364.902 1281.46 367.042 1277.26 367.869C1273.13 368.637 1269.38 368.366 1266.01 367.056C1262.64 365.746 1259.36 363.94 1256.16 361.637C1252.9 359.259 1249.86 356.77 1247.03 354.169C1244.21 351.434 1241.53 349.084 1238.97 347.12C1236.41 345.156 1233.85 343.933 1231.26 343.451C1228.76 342.91 1226.39 343.532 1224.18 345.317C1223.29 346.031 1222.66 346.908 1222.28 347.946L1227.83 354.824C1238.5 368.061 1248.29 378.166 1257.21 385.138L1220.91 414.416C1215.98 404.231 1208.18 392.519 1197.5 379.282L1187.66 367.08C1176.99 353.842 1167.19 343.738 1158.28 336.766L1194.58 307.488C1199.45 317.599 1206.66 328.571 1216.2 340.403L1216.47 340.736L1219.57 338.237C1223.18 335.321 1224.7 331.84 1224.12 327.794C1223.47 323.675 1220.89 318.806 1216.35 313.185C1212.36 308.23 1207.28 304.576 1201.12 302.221L1235.21 274.729C1241.01 277.855 1246.01 282.006 1250.19 287.182C1256.38 294.874 1258.33 301.665 1256.02 307.557C1253.65 313.375 1246.63 320.985 1234.97 330.387L1227.01 336.815L1228.17 338.257L1228.5 337.989C1231.3 335.728 1235.9 333.491 1242.27 331.279C1248.72 329.007 1253.71 326.443 1257.26 323.586C1265 317.338 1267.7 309.666 1265.37 300.57L1297.12 339.949C1298.17 343.371 1298 347.233 1296.6 351.537C1295.22 355.706 1293.01 359.011 1289.99 361.451Z"
        fill="#BCD7C4"
      />
      <path
        d="M1309.43 343.023C1304.5 332.838 1296.7 321.126 1286.03 307.889L1276.19 295.687C1265.51 282.449 1255.72 272.345 1246.81 265.373L1283.11 236.095C1288.04 246.28 1295.83 257.991 1306.52 271.228L1316.36 283.431C1327.03 296.668 1336.82 306.773 1345.73 313.745L1309.43 343.023Z"
        fill="#BCD7C4"
      />
      <path
        d="M1373.42 291.423C1368.49 281.238 1360.69 269.527 1350.01 256.289L1340.17 244.087C1329.5 230.85 1319.71 220.746 1310.79 213.774L1347.1 184.495C1352.03 194.681 1359.83 206.392 1370.5 219.629L1380.34 231.831C1391.02 245.068 1400.81 255.173 1409.72 262.145L1373.42 291.423Z"
        fill="#BCD7C4"
      />
      <path
        d="M1488.54 201.323C1484.26 204.775 1480.02 206.914 1475.82 207.742C1471.68 208.51 1467.93 208.238 1464.56 206.929C1461.19 205.619 1457.91 203.813 1454.72 201.509C1451.46 199.131 1448.41 196.642 1445.58 194.041C1442.77 191.306 1440.09 188.957 1437.53 186.993C1434.97 185.029 1432.4 183.805 1429.82 183.324C1427.31 182.782 1424.95 183.404 1422.74 185.19C1421.85 185.904 1421.22 186.78 1420.84 187.819L1426.39 194.697C1437.07 207.934 1446.86 218.039 1455.77 225.01L1419.46 254.289C1414.54 244.104 1406.74 232.392 1396.06 219.155L1386.22 206.952C1375.54 193.715 1365.75 183.61 1356.84 176.639L1393.15 147.361C1398.01 157.472 1405.21 168.443 1414.76 180.276L1415.03 180.609L1418.13 178.109C1421.74 175.193 1423.26 171.712 1422.68 167.667C1422.03 163.548 1419.44 158.678 1414.91 153.058C1410.92 148.103 1405.83 144.448 1399.68 142.094L1433.77 114.601C1439.58 117.727 1444.57 121.878 1448.74 127.055C1454.95 134.746 1456.89 141.537 1454.58 147.43C1452.21 153.247 1445.19 160.858 1433.54 170.26L1425.56 176.687L1426.73 178.129L1427.06 177.861C1429.86 175.6 1434.45 173.363 1440.83 171.151C1447.28 168.879 1452.27 166.315 1455.82 163.459C1463.56 157.21 1466.26 149.539 1463.92 140.443L1495.68 179.822C1496.74 183.244 1496.56 187.106 1495.16 191.409C1493.78 195.579 1491.57 198.884 1488.54 201.323Z"
        fill="#BCD7C4"
      />
      <path
        d="M1507.99 182.895C1503.06 172.71 1495.26 160.999 1484.59 147.762L1474.75 135.559C1464.07 122.322 1454.28 112.217 1445.36 105.245L1481.67 75.9672C1486.59 86.1524 1494.4 97.8636 1505.07 111.101L1514.91 123.304C1525.59 136.541 1535.38 146.646 1544.29 153.617L1507.99 182.895Z"
        fill="#BCD7C4"
      />
      <path
        d="M1571.97 131.296C1567.04 121.11 1559.24 109.399 1548.57 96.1621L1538.73 83.9598C1528.05 70.7226 1518.25 60.6179 1509.35 53.6462L1545.65 24.3679C1550.58 34.5531 1558.38 46.2643 1569.05 59.5018L1578.89 71.7041C1589.57 84.9413 1599.36 95.0455 1608.27 102.017L1571.97 131.296Z"
        fill="#BCD7C4"
      />
      <path
        d="M1687.1 41.1958C1682.82 44.6473 1678.58 46.7868 1674.37 47.6141C1670.24 48.382 1666.49 48.111 1663.12 46.8013C1659.75 45.4915 1656.47 43.6849 1653.27 41.3815C1650.01 39.004 1646.97 36.5147 1644.14 33.9137C1641.33 31.1792 1638.64 28.8298 1636.08 26.8655C1633.53 24.9011 1630.95 23.678 1628.38 23.1962C1625.87 22.6549 1623.51 23.2768 1621.29 25.0621C1620.41 25.7762 1619.78 26.6526 1619.4 27.6915L1624.94 34.569C1635.62 47.8064 1645.41 57.911 1654.32 64.8827L1618.02 94.161C1613.09 83.9758 1605.29 72.2646 1594.62 59.0271L1584.78 46.825C1574.1 33.5876 1564.31 23.483 1555.39 16.5114L1591.7 -12.7669C1596.57 -2.65575 1603.77 8.31604 1613.31 20.1483L1613.58 20.4812L1616.68 17.9818C1620.29 15.0659 1621.81 11.5852 1621.23 7.53974C1620.59 3.42039 1618 -1.44948 1613.47 -7.06983C1609.47 -12.0246 1604.39 -15.6792 1598.23 -18.0335L1632.32 -45.5265C1638.13 -42.4003 1643.12 -38.2489 1647.29 -33.0723C1653.5 -25.3813 1655.45 -18.5898 1653.13 -12.6979C1650.76 -6.87991 1643.75 0.730276 1632.09 10.1327L1624.12 16.5596L1625.28 18.0017L1625.61 17.7339C1628.42 15.4725 1633 13.2357 1639.38 11.0235C1645.83 8.7518 1650.82 6.18766 1654.37 3.33126C1662.12 -2.91715 1664.82 -10.5891 1662.47 -19.6846L1694.23 19.6948C1695.29 23.1163 1695.12 26.9785 1693.71 31.2816C1692.33 35.4512 1690.12 38.756 1687.1 41.1958Z"
        fill="#BCD7C4"
      />
      <path
        d="M1706.54 22.7679C1701.62 12.5828 1693.82 0.871467 1683.14 -12.366L1673.3 -24.568C1662.62 -37.8055 1652.83 -47.91 1643.92 -54.8817L1680.23 -84.1599C1685.15 -73.9747 1692.95 -62.2635 1703.62 -49.0261L1713.46 -36.8241C1724.15 -23.5866 1733.94 -13.4821 1742.85 -6.51036L1706.54 22.7679Z"
        fill="#BCD7C4"
      />
      <path
        d="M466.931 1199.06C462.005 1188.87 454.203 1177.15 443.528 1163.92L433.688 1151.72C423.012 1138.48 413.219 1128.37 404.309 1121.41L440.613 1092.13C445.539 1102.31 453.34 1114.02 464.015 1127.26L473.856 1139.46C484.531 1152.7 494.324 1162.8 503.235 1169.78L466.931 1199.06Z"
        fill="#BCD7C4"
      />
      <path
        d="M582.056 1108.95C577.776 1112.4 573.534 1114.54 569.33 1115.37C565.2 1116.14 561.45 1115.87 558.079 1114.56C554.71 1113.25 551.426 1111.44 548.229 1109.14C544.972 1106.76 541.929 1104.27 539.101 1101.67C536.287 1098.93 533.6 1096.59 531.042 1094.62C528.483 1092.66 525.914 1091.43 523.333 1090.95C520.826 1090.41 518.466 1091.03 516.253 1092.82C515.367 1093.53 514.734 1094.41 514.354 1095.45L519.901 1102.33C530.576 1115.56 540.369 1125.67 549.28 1132.64L512.975 1161.92C508.05 1151.74 500.249 1140.02 489.573 1126.78L479.732 1114.58C469.057 1101.35 459.264 1091.24 450.354 1084.27L486.658 1054.99C491.524 1065.1 498.729 1076.07 508.271 1087.9L508.539 1088.24L511.639 1085.74C515.254 1082.82 516.77 1079.34 516.187 1075.3C515.544 1071.18 512.956 1066.31 508.423 1060.69C504.428 1055.73 499.349 1052.08 493.188 1049.72L527.279 1022.23C533.088 1025.35 538.08 1029.51 542.255 1034.68C548.458 1042.38 550.403 1049.16 548.091 1055.06C545.72 1060.88 538.705 1068.49 527.046 1077.89L519.077 1084.32L520.24 1085.76L520.572 1085.49C523.376 1083.23 527.966 1080.99 534.341 1078.78C540.79 1076.51 545.786 1073.94 549.328 1071.09C557.075 1064.84 559.778 1057.17 557.436 1048.07L589.194 1087.45C590.248 1090.87 590.075 1094.74 588.674 1099.04C587.287 1103.21 585.081 1106.51 582.056 1108.95Z"
        fill="#BCD7C4"
      />
      <path
        d="M601.503 1090.52C596.577 1080.34 588.776 1068.63 578.101 1055.39L568.26 1043.19C557.585 1029.95 547.792 1019.85 538.881 1012.88L575.186 983.596C580.112 993.779 587.912 1005.49 598.588 1018.73L608.428 1030.93C619.104 1044.17 628.895 1054.27 637.808 1061.24L601.503 1090.52Z"
        fill="#BCD7C4"
      />
      <path
        d="M665.483 1038.93C660.554 1028.74 652.759 1017.03 642.078 1003.79L632.238 991.588C621.564 978.354 611.772 968.25 602.861 961.277L639.162 931.998C644.091 942.181 651.893 953.893 662.568 967.133L672.407 979.336C683.082 992.57 692.878 1002.67 701.785 1009.65L665.483 1038.93Z"
        fill="#BCD7C4"
      />
      <path
        d="M780.613 948.823C776.328 952.278 772.087 954.414 767.882 955.243C763.757 956.01 760.007 955.74 756.637 954.433C753.261 953.119 749.983 951.315 746.785 949.013C743.525 946.631 740.481 944.145 737.657 941.543C734.84 938.811 732.157 936.46 729.598 934.496C727.038 932.532 724.466 931.31 721.888 930.825C719.377 930.285 717.02 930.905 714.804 932.691C713.921 933.403 713.288 934.281 712.908 935.318L718.457 942.199C729.131 955.433 738.922 965.543 747.834 972.51L711.527 1001.79C706.604 991.606 698.802 979.894 688.128 966.654L678.288 954.457C667.613 941.217 657.817 931.114 648.91 924.141L685.212 894.861C690.08 904.971 697.28 915.946 706.825 927.781L707.095 928.112L710.195 925.614C713.81 922.698 715.326 919.212 714.743 915.166C714.099 911.048 711.508 906.18 706.978 900.557C702.982 895.604 697.906 891.951 691.743 889.594L725.835 862.101C731.642 865.232 736.632 869.381 740.806 874.556C747.012 882.247 748.958 889.042 746.644 894.929C744.274 900.748 737.258 908.359 725.602 917.763L717.628 924.19L718.794 925.632L719.126 925.362C721.931 923.103 726.522 920.863 732.894 918.653C739.345 916.382 744.342 913.816 747.884 910.962C755.63 904.713 758.331 897.04 755.992 887.943L787.745 927.326C788.801 930.745 788.629 934.606 787.23 938.909C785.843 943.083 783.633 946.386 780.613 948.823Z"
        fill="#BCD7C4"
      />
      <path
        d="M800.054 930.395C795.131 920.212 787.329 908.5 776.655 895.266L766.815 883.063C756.141 869.823 746.344 859.719 737.438 852.746L773.739 823.467C778.662 833.656 786.464 845.368 797.138 858.602L806.984 870.805C817.659 884.045 827.449 894.149 836.362 901.122L800.054 930.395Z"
        fill="#BCD7C4"
      />
      <path
        d="M864.039 878.797C859.11 868.614 851.308 856.902 840.633 843.662L830.794 831.459C820.119 818.225 810.329 808.121 801.416 801.148L837.718 771.869C842.647 782.052 850.448 793.764 861.123 807.004L870.963 819.207C881.637 832.441 891.434 842.551 900.34 849.518L864.039 878.797Z"
        fill="#BCD7C4"
      />
      <path
        d="M979.164 788.7C974.885 792.149 970.644 794.292 966.439 795.114C962.308 795.881 958.557 795.611 955.188 794.304C951.818 792.99 948.534 791.186 945.342 788.884C942.082 786.508 939.038 784.016 936.208 781.414C933.397 778.682 930.708 776.331 928.155 774.367C925.595 772.403 923.023 771.181 920.445 770.696C917.934 770.156 915.577 770.776 913.361 772.562C912.477 773.28 911.845 774.152 911.465 775.196L917.014 782.07C927.688 795.311 937.479 805.414 946.391 812.387L910.084 841.661C905.161 831.477 897.359 819.765 886.684 806.531L876.845 794.329C866.164 781.088 856.374 770.985 847.461 764.012L883.769 734.732C888.636 744.848 895.837 755.817 905.382 767.652L905.652 767.983L908.752 765.485C912.367 762.569 913.883 759.089 913.3 755.044C912.655 750.925 910.065 746.051 905.535 740.435C901.539 735.475 896.457 731.823 890.3 729.465L924.392 701.978C930.199 705.103 935.189 709.252 939.363 714.427C945.569 722.118 947.515 728.913 945.201 734.806C942.831 740.625 935.815 748.23 924.159 757.634L916.185 764.061L917.351 765.503L917.683 765.233C920.488 762.974 925.073 760.74 931.451 758.524C937.902 756.253 942.899 753.687 946.44 750.833C954.187 744.584 956.888 736.911 954.543 727.814L986.302 767.197C987.358 770.616 987.186 774.477 985.787 778.786C984.399 782.954 982.19 786.257 979.164 788.7Z"
        fill="#BCD7C4"
      />
      <path
        d="M998.607 770.273C993.684 760.083 985.882 748.371 975.208 735.137L965.368 722.934C954.688 709.694 944.897 699.59 935.984 692.617L972.292 663.344C977.215 673.527 985.017 685.239 995.691 698.473L1005.53 710.676C1016.21 723.916 1026 734.02 1034.91 740.993L998.607 770.273Z"
        fill="#BCD7C4"
      />
      <path
        d="M1062.59 718.668C1057.66 708.485 1049.86 696.773 1039.19 683.539L1029.35 671.336C1018.67 658.096 1008.88 647.992 999.971 641.019L1036.27 611.742C1041.2 621.929 1049 633.641 1059.68 646.875L1069.52 659.078C1080.19 672.318 1089.98 682.422 1098.9 689.395L1062.59 718.668Z"
        fill="#BCD7C4"
      />
      <path
        d="M1177.72 628.571C1173.44 632.021 1169.2 634.163 1164.99 634.991C1160.86 635.759 1157.11 635.483 1153.74 634.175C1150.37 632.868 1147.09 631.057 1143.89 628.755C1140.64 626.379 1137.59 623.887 1134.76 621.291C1131.95 618.553 1129.26 616.202 1126.7 614.238C1124.15 612.276 1121.58 611.052 1119 610.57C1116.49 610.03 1114.13 610.651 1111.91 612.436C1111.03 613.151 1110.4 614.029 1110.02 615.067L1115.56 621.941C1126.24 635.182 1136.03 645.285 1144.94 652.258L1108.64 681.538C1103.71 671.348 1095.91 659.637 1085.24 646.402L1075.4 634.2C1064.72 620.959 1054.93 610.858 1046.01 603.886L1082.32 574.607C1087.19 584.718 1094.39 595.691 1103.93 607.523L1104.2 607.855L1107.3 605.356C1110.91 602.44 1112.43 598.96 1111.85 594.914C1111.21 590.795 1108.62 585.925 1104.09 580.304C1100.09 575.35 1095.01 571.695 1088.85 569.341L1122.94 541.848C1128.75 544.974 1133.74 549.126 1137.92 554.302C1144.12 561.993 1146.07 568.785 1143.75 574.677C1141.38 580.495 1134.37 588.105 1122.71 597.507L1114.74 603.934L1115.9 605.376L1116.24 605.109C1119.04 602.847 1123.63 600.61 1130 598.398C1136.45 596.126 1141.45 593.562 1144.99 590.706C1152.74 584.458 1155.44 576.785 1153.1 567.69L1184.86 607.069C1185.91 610.491 1185.74 614.355 1184.33 618.658C1182.95 622.825 1180.74 626.128 1177.72 628.571Z"
        fill="#BCD7C4"
      />
      <path
        d="M1197.16 610.142C1192.24 599.957 1184.44 588.246 1173.76 575.008L1163.92 562.806C1153.24 549.569 1143.45 539.465 1134.54 532.493L1170.85 503.214C1175.77 513.4 1183.57 525.111 1194.25 538.348L1204.09 550.55C1214.77 563.787 1224.56 573.892 1233.47 580.864L1197.16 610.142Z"
        fill="#BCD7C4"
      />
      <path
        d="M1261.15 558.542C1256.22 548.357 1248.42 536.646 1237.74 523.409L1227.9 511.206C1217.23 497.969 1207.44 487.864 1198.53 480.893L1234.83 451.614C1239.76 461.799 1247.56 473.511 1258.23 486.748L1268.07 498.95C1278.75 512.188 1288.54 522.292 1297.45 529.264L1261.15 558.542Z"
        fill="#BCD7C4"
      />
      <path
        d="M1376.28 468.442C1371.99 471.894 1367.75 474.033 1363.55 474.861C1359.42 475.628 1355.67 475.358 1352.3 474.048C1348.93 472.738 1345.65 470.931 1342.45 468.628C1339.19 466.25 1336.14 463.761 1333.32 461.16C1330.5 458.426 1327.82 456.076 1325.26 454.112C1322.7 452.148 1320.13 450.924 1317.55 450.443C1315.05 449.901 1312.68 450.524 1310.47 452.309C1309.58 453.022 1308.95 453.899 1308.57 454.938L1314.12 461.815C1324.8 475.053 1334.59 485.157 1343.5 492.129L1307.19 521.408C1302.27 511.222 1294.47 499.511 1283.79 486.273L1273.95 474.072C1263.28 460.834 1253.48 450.73 1244.57 443.758L1280.88 414.48C1285.74 424.591 1292.94 435.563 1302.49 447.395L1302.76 447.728L1305.86 445.228C1309.47 442.312 1310.99 438.831 1310.41 434.786C1309.76 430.667 1307.17 425.797 1302.64 420.177C1298.65 415.222 1293.57 411.567 1287.41 409.213L1321.5 381.72C1327.31 384.846 1332.3 388.997 1336.47 394.174C1342.68 401.866 1344.62 408.657 1342.31 414.548C1339.94 420.367 1332.92 427.976 1321.27 437.379L1313.3 443.806L1314.46 445.248L1314.79 444.98C1317.6 442.719 1322.19 440.482 1328.56 438.27C1335.01 435.998 1340.01 433.434 1343.55 430.578C1351.29 424.329 1353.99 416.657 1351.66 407.562L1383.41 446.941C1384.47 450.363 1384.29 454.225 1382.89 458.528C1381.51 462.698 1379.3 466.002 1376.28 468.442Z"
        fill="#BCD7C4"
      />
      <path
        d="M1395.72 450.014C1390.79 439.829 1382.99 428.118 1372.32 414.88L1362.48 402.678C1351.8 389.441 1342.01 379.336 1333.1 372.365L1369.4 343.087C1374.32 353.272 1382.13 364.983 1392.8 378.22L1402.65 390.422C1413.32 403.66 1423.11 413.765 1432.02 420.736L1395.72 450.014Z"
        fill="#BCD7C4"
      />
      <path
        d="M1459.7 398.415C1454.77 388.23 1446.98 376.519 1436.3 363.281L1426.46 351.079C1415.78 337.842 1405.99 327.737 1397.08 320.766L1433.38 291.487C1438.31 301.672 1446.11 313.384 1456.79 326.621L1466.63 338.823C1477.3 352.06 1487.1 362.165 1496 369.137L1459.7 398.415Z"
        fill="#BCD7C4"
      />
      <path
        d="M1574.83 308.315C1570.55 311.767 1566.31 313.906 1562.1 314.733C1557.97 315.501 1554.22 315.23 1550.85 313.921C1547.48 312.611 1544.2 310.804 1541 308.501C1537.74 306.123 1534.7 303.634 1531.88 301.033C1529.06 298.298 1526.37 295.949 1523.82 293.985C1521.26 292.021 1518.69 290.797 1516.11 290.315C1513.6 289.774 1511.24 290.396 1509.02 292.181C1508.14 292.895 1507.51 293.772 1507.13 294.81L1512.68 301.688C1523.35 314.926 1533.14 325.03 1542.05 332.002L1505.75 361.28C1500.82 351.095 1493.02 339.383 1482.35 326.146L1472.51 313.944C1461.83 300.707 1452.04 290.602 1443.12 283.63L1479.43 254.352C1484.3 264.463 1491.5 275.435 1501.04 287.268L1501.31 287.6L1504.41 285.101C1508.03 282.185 1509.55 278.704 1508.96 274.659C1508.32 270.54 1505.73 265.67 1501.2 260.049C1497.2 255.095 1492.12 251.44 1485.96 249.086L1520.05 221.593C1525.86 224.719 1530.85 228.87 1535.03 234.047C1541.23 241.738 1543.18 248.529 1540.86 254.421C1538.49 260.239 1531.48 267.849 1519.82 277.252L1511.85 283.679L1513.01 285.121L1513.34 284.853C1516.15 282.592 1520.74 280.355 1527.11 278.143C1533.56 275.871 1538.56 273.307 1542.1 270.45C1549.85 264.202 1552.55 256.53 1550.21 247.435L1581.96 286.814C1583.02 290.236 1582.85 294.098 1581.45 298.401C1580.06 302.57 1577.85 305.875 1574.83 308.315Z"
        fill="#BCD7C4"
      />
      <path
        d="M1594.27 289.887C1589.35 279.702 1581.55 267.991 1570.87 254.753L1561.03 242.551C1550.35 229.314 1540.56 219.209 1531.65 212.237L1567.96 182.959C1572.88 193.144 1580.68 204.855 1591.36 218.093L1601.2 230.295C1611.88 243.533 1621.67 253.637 1630.58 260.609L1594.27 289.887Z"
        fill="#BCD7C4"
      />
      <path
        d="M1658.26 238.287C1653.33 228.102 1645.53 216.391 1634.85 203.154L1625.01 190.952C1614.34 177.714 1604.54 167.61 1595.63 160.638L1631.94 131.359C1636.87 141.545 1644.67 153.256 1655.34 166.494L1665.18 178.695C1675.86 191.933 1685.65 202.037 1694.56 209.009L1658.26 238.287Z"
        fill="#BCD7C4"
      />
      <path
        d="M1773.38 148.188C1769.1 151.639 1764.86 153.778 1760.66 154.606C1756.52 155.374 1752.77 155.103 1749.4 153.793C1746.03 152.483 1742.75 150.677 1739.55 148.373C1736.3 145.996 1733.25 143.507 1730.42 140.905C1727.61 138.171 1724.92 135.821 1722.37 133.857C1719.81 131.893 1717.24 130.67 1714.66 130.188C1712.15 129.646 1709.79 130.269 1707.58 132.054C1706.69 132.768 1706.06 133.644 1705.68 134.683L1711.22 141.561C1721.9 154.798 1731.7 164.903 1740.61 171.875L1704.3 201.153C1699.38 190.968 1691.58 179.256 1680.9 166.019L1671.06 153.817C1660.38 140.579 1650.59 130.475 1641.68 123.503L1677.99 94.2249C1682.85 104.336 1690.05 115.308 1699.6 127.14L1699.87 127.473L1702.96 124.973C1706.58 122.058 1708.09 118.577 1707.51 114.532C1706.87 110.412 1704.28 105.542 1699.75 99.9218C1695.76 94.967 1690.67 91.3123 1684.52 88.9583L1718.6 61.465C1724.42 64.5912 1729.41 68.7431 1733.58 73.9195C1739.79 81.6108 1741.73 88.4021 1739.42 94.2936C1737.05 100.112 1730.03 107.722 1718.38 117.124L1710.4 123.551L1711.57 124.994L1711.9 124.725C1714.7 122.464 1719.29 120.227 1725.67 118.015C1732.12 115.743 1737.11 113.179 1740.65 110.323C1748.4 104.074 1751.1 96.4027 1748.76 87.3071L1780.52 126.686C1781.57 130.108 1781.4 133.97 1780 138.273C1778.62 142.443 1776.41 145.748 1773.38 148.188Z"
        fill="#BCD7C4"
      />
      <path
        d="M1792.83 129.76C1787.9 119.575 1780.1 107.863 1769.43 94.6256L1759.58 82.4234C1748.91 69.1862 1739.12 59.0816 1730.21 52.1099L1766.51 22.8316C1771.44 33.0168 1779.24 44.7281 1789.91 57.9655L1799.75 70.1677C1810.43 83.4049 1820.22 93.5097 1829.14 100.482L1792.83 129.76Z"
        fill="#BCD7C4"
      />
      <path
        d="M1856.81 78.1598C1851.88 67.9746 1844.08 56.2634 1833.41 43.026L1823.57 30.8239C1812.89 17.5865 1803.1 7.48201 1794.19 0.510293L1830.49 -28.768C1835.42 -18.5828 1843.22 -6.87152 1853.9 6.36583L1863.74 18.568C1874.41 31.8054 1884.2 41.9099 1893.11 48.8816L1856.81 78.1598Z"
        fill="#BCD7C4"
      />
      <path
        d="M767.619 1135.88C763.34 1139.33 759.099 1141.47 754.894 1142.3C750.763 1143.07 747.012 1142.8 743.643 1141.48C740.273 1140.18 736.989 1138.37 733.791 1136.06C730.537 1133.69 727.493 1131.2 724.663 1128.6C721.852 1125.86 719.163 1123.51 716.603 1121.55C714.05 1119.58 711.478 1118.36 708.894 1117.88C706.389 1117.34 704.032 1117.96 701.816 1119.75C700.933 1120.46 700.3 1121.34 699.92 1122.38L705.463 1129.25C716.137 1142.49 725.934 1152.59 734.84 1159.57L698.539 1188.85C693.616 1178.66 685.814 1166.95 675.14 1153.71L665.294 1141.51C654.619 1128.27 644.829 1118.16 635.916 1111.2L672.224 1081.92C677.085 1092.03 684.292 1103 693.837 1114.83L694.101 1115.16L697.2 1112.66C700.816 1109.75 702.332 1106.27 701.749 1102.22C701.111 1098.11 698.52 1093.24 693.99 1087.61C689.994 1082.66 684.912 1079 678.749 1076.65L712.841 1049.16C718.654 1052.28 723.644 1056.44 727.818 1061.61C734.024 1069.3 735.964 1076.09 733.656 1081.99C731.286 1087.8 724.27 1095.42 712.608 1104.81L704.64 1111.25L705.806 1112.68L706.138 1112.42C708.937 1110.15 713.528 1107.92 719.906 1105.71C726.351 1103.44 731.348 1100.87 734.889 1098.01C742.636 1091.77 745.343 1084.1 742.998 1075L774.757 1114.38C775.813 1117.8 775.641 1121.66 774.236 1125.97C772.848 1130.13 770.645 1133.44 767.619 1135.88Z"
        fill="#BCD7C4"
      />
      <path
        d="M787.066 1117.45C782.137 1107.27 774.341 1095.56 763.661 1082.32L753.821 1070.11C743.147 1056.88 733.356 1046.78 724.443 1039.8L760.751 1010.52C765.674 1020.71 773.476 1032.42 784.15 1045.66L793.99 1057.86C804.664 1071.1 814.461 1081.2 823.368 1088.17L787.066 1117.45Z"
        fill="#BCD7C4"
      />
      <path
        d="M851.044 1065.85C846.121 1055.67 838.32 1043.95 827.645 1030.72L817.806 1018.52C807.131 1005.28 797.334 995.173 788.428 988.206L824.729 958.926C829.652 969.109 837.454 980.821 848.129 994.061L857.974 1006.26C868.649 1019.5 878.439 1029.6 887.352 1036.58L851.044 1065.85Z"
        fill="#BCD7C4"
      />
      <path
        d="M966.173 975.751C961.895 979.207 957.653 981.343 953.449 982.172C949.318 982.939 945.567 982.669 942.197 981.355C938.827 980.048 935.543 978.243 932.345 975.935C929.092 973.56 926.048 971.074 923.218 968.471C920.406 965.733 917.718 963.389 915.158 961.424C912.599 959.46 910.033 958.232 907.449 957.754C904.944 957.213 902.581 957.833 900.371 959.62C899.487 960.332 898.849 961.209 898.474 962.247L904.017 969.128C914.692 982.362 924.488 992.465 933.395 999.439L897.093 1028.72C892.164 1018.53 884.369 1006.82 873.688 993.583L863.848 981.38C853.174 968.146 843.383 958.042 834.471 951.069L870.772 921.789C875.64 931.899 882.846 942.874 892.391 954.703L892.655 955.04L895.755 952.536C899.371 949.62 900.887 946.14 900.304 942.095C899.659 937.976 897.075 933.108 892.539 927.486C888.543 922.532 883.466 918.88 877.304 916.523L911.395 889.03C917.208 892.154 922.199 896.31 926.373 901.484C932.573 909.175 934.518 915.964 932.21 921.857C929.835 927.676 922.825 935.288 911.162 944.691L903.195 951.118L904.355 952.561L904.692 952.291C907.492 950.032 912.083 947.791 918.461 945.581C924.906 943.31 929.902 940.744 933.444 937.89C941.191 931.641 943.898 923.969 941.553 914.872L973.312 954.249C974.368 957.674 974.19 961.535 972.79 965.838C971.403 970.006 969.2 973.314 966.173 975.751Z"
        fill="#BCD7C4"
      />
      <path
        d="M985.619 957.324C980.69 947.141 972.894 935.429 962.213 922.189L952.374 909.992C941.699 896.752 931.909 886.648 922.996 879.675L959.298 850.396C964.227 860.585 972.028 872.291 982.703 885.531L992.543 897.734C1003.22 910.968 1013.01 921.078 1021.92 928.045L985.619 957.324Z"
        fill="#BCD7C4"
      />
      <path
        d="M1049.6 905.726C1044.68 895.542 1036.87 883.831 1026.2 870.59L1016.36 858.387C1005.68 845.153 995.889 835.05 986.977 828.077L1023.28 798.797C1028.21 808.981 1036.01 820.692 1046.68 833.933L1056.53 846.135C1067.2 859.37 1076.99 869.473 1085.91 876.446L1049.6 905.726Z"
        fill="#BCD7C4"
      />
      <path
        d="M1164.73 815.622C1160.45 819.078 1156.21 821.214 1152 822.043C1147.87 822.81 1144.12 822.54 1140.75 821.232C1137.38 819.919 1134.1 818.114 1130.9 815.812C1127.64 813.431 1124.6 810.945 1121.77 808.342C1118.95 805.611 1116.27 803.26 1113.71 801.295C1111.15 799.331 1108.59 798.11 1106 797.625C1103.5 797.085 1101.14 797.704 1098.93 799.491C1098.04 800.203 1097.4 801.081 1097.02 802.118L1102.57 808.999C1113.25 822.233 1123.04 832.343 1131.95 839.31L1095.65 868.589C1090.72 858.406 1082.92 846.694 1072.24 833.454L1062.4 821.257C1051.73 808.017 1041.94 797.913 1033.03 790.94L1069.33 761.661C1074.19 771.776 1081.4 782.746 1090.94 794.58L1091.21 794.912L1094.31 792.413C1097.93 789.498 1099.44 786.017 1098.86 781.966C1098.21 777.847 1095.63 772.98 1091.09 767.357C1087.1 762.403 1082.02 758.751 1075.86 756.394L1109.95 728.901C1115.76 732.031 1120.75 736.181 1124.93 741.355C1131.13 749.046 1133.07 755.841 1130.76 761.734C1128.39 767.547 1121.37 775.159 1109.72 784.562L1101.75 790.989L1102.91 792.432L1103.24 792.162C1106.05 789.903 1110.64 787.662 1117.02 785.452C1123.46 783.181 1128.46 780.616 1132 777.761C1139.75 771.512 1142.45 763.84 1140.11 754.743L1171.87 794.126C1172.92 797.545 1172.74 801.406 1171.35 805.709C1169.96 809.883 1167.75 813.185 1164.73 815.622Z"
        fill="#BCD7C4"
      />
      <path
        d="M1184.18 797.195C1179.25 787.012 1171.44 775.3 1160.77 762.066L1150.93 749.863C1140.26 736.623 1130.46 726.519 1121.55 719.546L1157.85 690.266C1162.78 700.456 1170.59 712.168 1181.26 725.402L1191.1 737.605C1201.77 750.845 1211.57 760.948 1220.48 767.922L1184.18 797.195Z"
        fill="#BCD7C4"
      />
      <path
        d="M1248.15 745.597C1243.23 735.413 1235.43 723.702 1224.75 710.461L1214.92 698.258C1204.23 685.024 1194.44 674.921 1185.53 667.948L1221.84 638.668C1226.76 648.858 1234.56 660.563 1245.24 673.804L1255.08 686.006C1265.76 699.24 1275.55 709.35 1284.46 716.317L1248.15 745.597Z"
        fill="#BCD7C4"
      />
      <path
        d="M1363.28 655.499C1359 658.949 1354.76 661.091 1350.56 661.914C1346.43 662.681 1342.68 662.411 1339.31 661.104C1335.94 659.796 1332.65 657.985 1329.45 655.684C1326.2 653.308 1323.16 650.816 1320.33 648.213C1317.51 645.482 1314.83 643.131 1312.27 641.167C1309.71 639.202 1307.14 637.981 1304.56 637.496C1302.05 636.956 1299.69 637.576 1297.48 639.362C1296.59 640.08 1295.96 640.952 1295.58 641.995L1301.13 648.87C1311.8 662.11 1321.59 672.214 1330.5 679.187L1294.2 708.46C1289.27 698.277 1281.47 686.565 1270.8 673.331L1260.96 661.128C1250.28 647.888 1240.49 637.784 1231.58 630.811L1267.88 601.535C1272.75 611.646 1279.96 622.617 1289.49 634.451L1289.76 634.783L1292.86 632.284C1296.48 629.369 1298 625.888 1297.41 621.843C1296.77 617.725 1294.18 612.852 1289.65 607.232C1285.65 602.277 1280.58 598.623 1274.41 596.268L1308.5 568.775C1314.31 571.901 1319.31 576.053 1323.48 581.229C1329.68 588.921 1331.63 595.712 1329.32 601.604C1326.94 607.422 1319.93 615.03 1308.27 624.434L1300.3 630.86L1301.46 632.303L1301.8 632.033C1304.6 629.774 1309.19 627.54 1315.56 625.324C1322.02 623.053 1327.01 620.487 1330.55 617.632C1338.3 611.384 1341 603.713 1338.66 594.617L1370.42 633.997C1371.47 637.416 1371.3 641.283 1369.9 645.586C1368.51 649.754 1366.31 653.056 1363.28 655.499Z"
        fill="#BCD7C4"
      />
      <path
        d="M1382.73 637.072C1377.8 626.883 1370 615.171 1359.32 601.935L1349.49 589.733C1338.81 576.496 1329.01 566.391 1320.11 559.42L1356.41 530.142C1361.34 540.327 1369.13 552.038 1379.81 565.275L1389.65 577.478C1400.33 590.715 1410.12 600.82 1419.03 607.791L1382.73 637.072Z"
        fill="#BCD7C4"
      />
      <path
        d="M1446.71 585.47C1441.79 575.284 1433.98 563.573 1423.31 550.336L1413.46 538.134C1402.79 524.897 1393 514.792 1384.09 507.82L1420.39 478.542C1425.32 488.727 1433.12 500.438 1443.79 513.676L1453.63 525.878C1464.31 539.115 1474.1 549.22 1483.01 556.191L1446.71 585.47Z"
        fill="#BCD7C4"
      />
      <path
        d="M1561.84 495.37C1557.55 498.821 1553.31 500.96 1549.11 501.788C1544.98 502.556 1541.23 502.285 1537.86 500.975C1534.49 499.665 1531.21 497.859 1528.01 495.555C1524.75 493.178 1521.71 490.689 1518.88 488.088C1516.06 485.353 1513.38 483.004 1510.82 481.039C1508.26 479.075 1505.69 477.852 1503.11 477.37C1500.61 476.829 1498.24 477.451 1496.03 479.236C1495.15 479.95 1494.51 480.826 1494.13 481.866L1499.68 488.743C1510.36 501.981 1520.15 512.085 1529.06 519.057L1492.76 548.335C1487.83 538.15 1480.03 526.439 1469.35 513.201L1459.51 500.999C1448.84 487.761 1439.04 477.657 1430.13 470.685L1466.44 441.407C1471.3 451.518 1478.51 462.49 1488.05 474.322L1488.32 474.655L1491.42 472.155C1495.03 469.24 1496.55 465.759 1495.97 461.714C1495.32 457.594 1492.73 452.725 1488.2 447.104C1484.21 442.149 1479.13 438.495 1472.97 436.14L1507.06 408.648C1512.87 411.773 1517.86 415.925 1522.04 421.102C1528.24 428.793 1530.18 435.584 1527.87 441.476C1525.5 447.294 1518.48 454.904 1506.83 464.306L1498.86 470.734L1500.02 472.176L1500.35 471.908C1503.16 469.647 1507.75 467.41 1514.12 465.198C1520.57 462.926 1525.57 460.361 1529.11 457.505C1536.85 451.257 1539.56 443.585 1537.22 434.489L1568.98 473.869C1570.03 477.29 1569.85 481.152 1568.45 485.456C1567.07 489.625 1564.86 492.93 1561.84 495.37Z"
        fill="#BCD7C4"
      />
      <path
        d="M1581.28 476.942C1576.35 466.757 1568.55 455.045 1557.88 441.808L1548.04 429.605C1537.36 416.368 1527.57 406.264 1518.66 399.292L1554.96 370.014C1559.89 380.199 1567.69 391.91 1578.37 405.148L1588.21 417.35C1598.88 430.587 1608.67 440.692 1617.58 447.664L1581.28 476.942Z"
        fill="#BCD7C4"
      />
      <path
        d="M1645.26 425.343C1640.34 415.157 1632.54 403.446 1621.86 390.208L1612.02 378.006C1601.34 364.769 1591.55 354.664 1582.64 347.693L1618.95 318.415C1623.87 328.6 1631.67 340.311 1642.35 353.548L1652.19 365.75C1662.87 378.988 1672.66 389.092 1681.57 396.064L1645.26 425.343Z"
        fill="#BCD7C4"
      />
      <path
        d="M1760.39 335.243C1756.11 338.694 1751.87 340.833 1747.67 341.661C1743.54 342.429 1739.79 342.157 1736.42 340.848C1733.05 339.538 1729.76 337.732 1726.56 335.428C1723.3 333.051 1720.27 330.561 1717.44 327.96C1714.63 325.226 1711.94 322.877 1709.38 320.912C1706.82 318.947 1704.25 317.725 1701.67 317.243C1699.16 316.701 1696.8 317.323 1694.59 319.109C1693.7 319.823 1693.07 320.699 1692.69 321.738L1698.24 328.616C1708.91 341.853 1718.71 351.958 1727.61 358.929L1691.31 388.207C1686.38 378.022 1678.58 366.311 1667.91 353.074L1658.07 340.871C1647.39 327.634 1637.6 317.529 1628.69 310.558L1664.99 281.279C1669.86 291.391 1677.07 302.362 1686.6 314.195L1686.87 314.528L1689.97 312.028C1693.59 309.112 1695.11 305.632 1694.52 301.587C1693.88 297.467 1691.29 292.597 1686.76 286.977C1682.76 282.022 1677.69 278.367 1671.52 276.013L1705.61 248.52C1711.42 251.646 1716.42 255.798 1720.59 260.975C1726.79 268.665 1728.74 275.457 1726.43 281.349C1724.05 287.167 1717.04 294.777 1705.38 304.179L1697.41 310.606L1698.57 312.048L1698.91 311.78C1701.71 309.519 1706.3 307.282 1712.68 305.07C1719.12 302.798 1724.12 300.234 1727.66 297.378C1735.41 291.129 1738.12 283.457 1735.77 274.362L1767.53 313.742C1768.59 317.163 1768.41 321.025 1767.01 325.328C1765.62 329.498 1763.42 332.803 1760.39 335.243Z"
        fill="#BCD7C4"
      />
      <path
        d="M1779.84 316.814C1774.91 306.629 1767.11 294.918 1756.43 281.681L1746.59 269.478C1735.92 256.241 1726.13 246.136 1717.21 239.165L1753.52 209.886C1758.45 220.072 1766.25 231.783 1776.92 245.021L1786.76 257.222C1797.44 270.46 1807.23 280.564 1816.14 287.536L1779.84 316.814Z"
        fill="#BCD7C4"
      />
      <path
        d="M1843.82 265.215C1838.9 255.03 1831.09 243.318 1820.42 230.081L1810.58 217.879C1799.9 204.641 1790.11 194.537 1781.2 187.565L1817.5 158.287C1822.43 168.472 1830.23 180.183 1840.9 193.42L1850.74 205.623C1861.42 218.86 1871.21 228.965 1880.13 235.937L1843.82 265.215Z"
        fill="#BCD7C4"
      />
      <path
        d="M953.185 1162.81C948.901 1166.26 944.659 1168.4 940.454 1169.23C936.329 1170 932.579 1169.72 929.209 1168.41C925.839 1167.11 922.555 1165.29 919.357 1162.99C916.098 1160.62 913.053 1158.12 910.229 1155.53C907.412 1152.79 904.73 1150.44 902.17 1148.48C899.61 1146.51 897.038 1145.29 894.46 1144.81C891.956 1144.26 889.593 1144.89 887.377 1146.68C886.493 1147.39 885.861 1148.27 885.48 1149.3L891.029 1156.18C901.703 1169.42 911.494 1179.52 920.407 1186.5L884.105 1215.78C879.176 1205.59 871.374 1193.87 860.7 1180.64L850.86 1168.44C840.186 1155.2 830.389 1145.09 821.482 1138.12L857.784 1108.85C862.652 1118.96 869.858 1129.93 879.397 1141.76L879.667 1142.09L882.767 1139.59C886.382 1136.68 887.898 1133.2 887.315 1129.15C886.671 1125.03 884.08 1120.16 879.55 1114.54C875.554 1109.59 870.478 1105.93 864.315 1103.58L898.407 1076.09C904.214 1079.21 909.204 1083.36 913.385 1088.54C919.584 1096.23 921.53 1103.02 919.216 1108.91C916.847 1114.73 909.83 1122.34 898.174 1131.74L890.206 1138.17L891.367 1139.61L891.698 1139.35C894.503 1137.08 899.095 1134.85 905.466 1132.63C911.917 1130.36 916.914 1127.8 920.456 1124.94C928.202 1118.69 930.903 1111.02 928.564 1101.93L960.318 1141.31C961.373 1144.73 961.202 1148.59 959.802 1152.9C958.415 1157.06 956.205 1160.37 953.185 1162.81Z"
        fill="#BCD7C4"
      />
      <path
        d="M972.624 1144.38C967.701 1134.19 959.9 1122.49 949.225 1109.25L939.386 1097.04C928.711 1083.81 918.914 1073.7 910.008 1066.73L946.31 1037.45C951.232 1047.64 959.034 1059.35 969.715 1072.59L979.554 1084.79C990.229 1098.03 1000.02 1108.13 1008.93 1115.1L972.624 1144.38Z"
        fill="#BCD7C4"
      />
      <path
        d="M1036.61 1092.78C1031.68 1082.59 1023.89 1070.88 1013.21 1057.65L1003.37 1045.45C992.692 1032.2 982.901 1022.1 973.988 1015.13L1010.29 985.855C1015.22 996.038 1023.02 1007.75 1033.7 1020.98L1043.53 1033.19C1054.21 1046.43 1064.01 1056.53 1072.91 1063.5L1036.61 1092.78Z"
        fill="#BCD7C4"
      />
      <path
        d="M1151.73 1002.68C1147.46 1006.13 1143.21 1008.27 1139.01 1009.1C1134.88 1009.87 1131.13 1009.6 1127.76 1008.28C1124.39 1006.98 1121.1 1005.17 1117.91 1002.86C1114.65 1000.49 1111.61 998.002 1108.78 995.399C1105.97 992.662 1103.28 990.317 1100.72 988.353C1098.16 986.389 1095.59 985.161 1093.01 984.682C1090.5 984.142 1088.15 984.762 1085.93 986.548C1085.05 987.26 1084.42 988.138 1084.03 989.175L1089.58 996.056C1100.26 1009.29 1110.05 1019.39 1118.96 1026.37L1082.65 1055.65C1077.73 1045.46 1069.93 1033.75 1059.25 1020.51L1049.41 1008.31C1038.74 995.074 1028.94 984.964 1020.04 977.998L1056.34 948.718C1061.21 958.828 1068.41 969.803 1077.95 981.631L1078.22 981.963L1081.32 979.465C1084.94 976.549 1086.45 973.068 1085.87 969.023C1085.23 964.905 1082.64 960.037 1078.11 954.414C1074.11 949.461 1069.03 945.802 1062.87 943.451L1096.96 915.958C1102.77 919.082 1107.76 923.238 1111.93 928.413C1118.14 936.104 1120.08 942.893 1117.77 948.785C1115.4 954.605 1108.39 962.216 1096.73 971.62L1088.75 978.047L1089.92 979.489L1090.25 979.219C1093.06 976.954 1097.65 974.72 1104.02 972.51C1110.47 970.239 1115.47 967.673 1119.01 964.819C1126.76 958.57 1129.46 950.897 1127.12 941.8L1158.87 981.177C1159.93 984.602 1159.76 988.463 1158.36 992.766C1156.97 996.934 1154.76 1000.24 1151.73 1002.68Z"
        fill="#BCD7C4"
      />
      <path
        d="M1171.18 984.253C1166.26 974.069 1158.45 962.357 1147.78 949.117L1137.94 936.914C1127.26 923.68 1117.47 913.577 1108.56 906.603L1144.86 877.324C1149.79 887.507 1157.59 899.219 1168.26 912.459L1178.11 924.662C1188.78 937.896 1198.57 948 1207.49 954.973L1171.18 984.253Z"
        fill="#BCD7C4"
      />
      <path
        d="M1235.17 932.654C1230.24 922.471 1222.43 910.759 1211.76 897.519L1201.92 885.316C1191.25 872.082 1181.46 861.972 1172.54 855.005L1208.84 825.726C1213.77 835.909 1221.58 847.621 1232.25 860.861L1242.09 873.064C1252.76 886.298 1262.56 896.402 1271.47 903.375L1235.17 932.654Z"
        fill="#BCD7C4"
      />
      <path
        d="M1350.29 842.551C1346.01 846.007 1341.77 848.143 1337.56 848.971C1333.43 849.739 1329.68 849.468 1326.31 848.161C1322.94 846.847 1319.66 845.043 1316.46 842.741C1313.21 840.359 1310.16 837.873 1307.33 835.271C1304.52 832.539 1301.83 830.188 1299.28 828.224C1296.72 826.26 1294.15 825.038 1291.57 824.553C1289.06 824.013 1286.7 824.633 1284.48 826.419C1283.6 827.131 1282.97 828.009 1282.59 829.046L1288.13 835.927C1298.81 849.162 1308.6 859.271 1317.51 866.238L1281.21 895.518C1276.28 885.334 1268.48 873.623 1257.81 860.382L1247.97 848.179C1237.29 834.945 1227.5 824.842 1218.58 817.869L1254.89 788.589C1259.76 798.699 1266.96 809.674 1276.5 821.509L1276.77 821.84L1279.87 819.342C1283.49 816.426 1285.01 812.94 1284.42 808.895C1283.78 804.776 1281.19 799.908 1276.66 794.285C1272.66 789.332 1267.58 785.68 1261.42 783.323L1295.51 755.829C1301.32 758.96 1306.31 763.109 1310.49 768.284C1316.69 775.975 1318.64 782.77 1316.32 788.657C1313.95 794.476 1306.94 802.087 1295.28 811.491L1287.31 817.918L1288.47 819.36L1288.81 819.09C1291.61 816.831 1296.2 814.591 1302.57 812.381C1309.03 810.11 1314.02 807.544 1317.56 804.69C1325.31 798.441 1328.01 790.768 1325.67 781.671L1357.43 821.054C1358.48 824.473 1358.31 828.334 1356.91 832.637C1355.52 836.811 1353.31 840.114 1350.29 842.551Z"
        fill="#BCD7C4"
      />
      <path
        d="M1369.74 824.124C1364.81 813.94 1357.01 802.228 1346.34 788.994L1336.5 776.791C1325.82 763.551 1316.03 753.448 1307.11 746.474L1343.42 717.195C1348.34 727.384 1356.15 739.096 1366.82 752.33L1376.66 764.533C1387.34 777.773 1397.13 787.877 1406.04 794.844L1369.74 824.124Z"
        fill="#BCD7C4"
      />
      <path
        d="M1433.72 772.525C1428.79 762.342 1420.99 750.63 1410.32 737.39L1400.48 725.187C1389.8 711.953 1380 701.849 1371.1 694.876L1407.4 665.597C1412.33 675.78 1420.13 687.492 1430.8 700.732L1440.64 712.935C1451.32 726.169 1461.11 736.273 1470.02 743.246L1433.72 772.525Z"
        fill="#BCD7C4"
      />
      <path
        d="M1548.84 682.428C1544.56 685.878 1540.32 688.014 1536.12 688.842C1531.99 689.61 1528.24 689.34 1524.87 688.032C1521.5 686.719 1518.21 684.914 1515.02 682.612C1511.76 680.237 1508.72 677.744 1505.89 675.142C1503.08 672.41 1500.39 670.059 1497.83 668.095C1495.27 666.131 1492.7 664.909 1490.12 664.424C1487.61 663.884 1485.26 664.504 1483.04 666.29C1482.16 667.009 1481.52 667.88 1481.14 668.924L1486.69 675.799C1497.37 689.039 1507.16 699.142 1516.07 706.109L1479.76 735.389C1474.84 725.205 1467.04 713.494 1456.36 700.26L1446.52 688.057C1435.84 674.816 1426.05 664.713 1417.14 657.74L1453.45 628.46C1458.31 638.576 1465.52 649.545 1475.06 661.38L1475.33 661.711L1478.43 659.213C1482.04 656.297 1483.56 652.817 1482.97 648.772C1482.34 644.653 1479.74 639.779 1475.21 634.163C1471.22 629.203 1466.14 625.551 1459.98 623.194L1494.07 595.703C1499.88 598.83 1504.87 602.981 1509.04 608.157C1515.25 615.846 1517.19 622.641 1514.88 628.534C1512.51 634.347 1505.49 641.958 1493.83 651.362L1485.86 657.789L1487.03 659.231L1487.36 658.961C1490.17 656.702 1494.75 654.468 1501.13 652.252C1507.58 649.981 1512.57 647.415 1516.11 644.561C1523.87 638.312 1526.57 630.639 1524.22 621.542L1555.98 660.926C1557.04 664.345 1556.87 668.205 1555.46 672.508C1554.08 676.682 1551.87 679.985 1548.84 682.428Z"
        fill="#BCD7C4"
      />
      <path
        d="M1568.29 663.994C1563.37 653.811 1555.56 642.099 1544.89 628.865L1535.04 616.662C1524.37 603.424 1514.58 593.32 1505.67 586.348L1541.97 557.07C1546.9 567.255 1554.7 578.966 1565.37 592.203L1575.21 604.405C1585.89 617.644 1595.68 627.748 1604.6 634.721L1568.29 663.994Z"
        fill="#BCD7C4"
      />
      <path
        d="M1632.27 612.397C1627.35 602.212 1619.54 590.501 1608.87 577.264L1599.03 565.062C1588.36 551.824 1578.56 541.72 1569.65 534.748L1605.95 505.469C1610.88 515.655 1618.68 527.366 1629.36 540.604L1639.2 552.805C1649.87 566.043 1659.66 576.147 1668.58 583.119L1632.27 612.397Z"
        fill="#BCD7C4"
      />
      <path
        d="M1747.4 522.298C1743.12 525.749 1738.88 527.888 1734.67 528.716C1730.54 529.484 1726.79 529.213 1723.42 527.903C1720.05 526.593 1716.77 524.787 1713.57 522.483C1710.31 520.106 1707.27 517.617 1704.44 515.016C1701.63 512.281 1698.94 509.932 1696.38 507.967C1693.82 506.003 1691.26 504.78 1688.67 504.298C1686.17 503.757 1683.81 504.379 1681.59 506.164C1680.71 506.878 1680.08 507.754 1679.7 508.794L1685.24 515.671C1695.91 528.909 1705.71 539.013 1714.62 545.985L1678.32 575.263C1673.39 565.078 1665.59 553.367 1654.92 540.129L1645.07 527.927C1634.4 514.689 1624.61 504.585 1615.69 497.613L1652 468.335C1656.86 478.446 1664.07 489.418 1673.61 501.25L1673.88 501.583L1676.98 499.083C1680.59 496.168 1682.11 492.687 1681.53 488.642C1680.88 484.522 1678.3 479.653 1673.76 474.032C1669.77 469.077 1664.69 465.423 1658.53 463.068L1692.62 435.576C1698.43 438.702 1703.42 442.853 1707.6 448.03C1713.8 455.721 1715.74 462.512 1713.43 468.404C1711.06 474.222 1704.05 481.832 1692.38 491.234L1684.42 497.662L1685.58 499.104L1685.92 498.836C1688.71 496.575 1693.31 494.338 1699.68 492.126C1706.13 489.854 1711.12 487.289 1714.67 484.433C1722.41 478.185 1725.12 470.513 1722.78 461.417L1754.53 500.797C1755.59 504.218 1755.42 508.08 1754.01 512.384C1752.63 516.553 1750.42 519.858 1747.4 522.298Z"
        fill="#BCD7C4"
      />
      <path
        d="M1766.84 503.87C1761.91 493.685 1754.12 481.974 1743.44 468.736L1733.6 456.534C1722.92 443.296 1713.13 433.192 1704.22 426.22L1740.53 396.942C1745.45 407.127 1753.25 418.838 1763.93 432.076L1773.77 444.278C1784.44 457.515 1794.24 467.62 1803.15 474.592L1766.84 503.87Z"
        fill="#BCD7C4"
      />
      <path
        d="M1830.83 452.27C1825.9 442.085 1818.1 430.374 1807.42 417.136L1797.58 404.934C1786.91 391.697 1777.12 381.592 1768.21 374.621L1804.51 345.343C1809.44 355.528 1817.24 367.239 1827.91 380.476L1837.75 392.678C1848.43 405.916 1858.22 416.02 1867.13 422.992L1830.83 452.27Z"
        fill="#BCD7C4"
      />
      <path
        d="M1945.95 362.171C1941.67 365.621 1937.43 367.761 1933.23 368.589C1929.1 369.357 1925.35 369.085 1921.98 367.776C1918.61 366.466 1915.32 364.66 1912.12 362.356C1908.87 359.979 1905.83 357.489 1903 354.888C1900.19 352.153 1897.5 349.804 1894.94 347.84C1892.38 345.875 1889.81 344.653 1887.23 344.171C1884.72 343.629 1882.37 344.251 1880.15 346.037C1879.27 346.751 1878.63 347.627 1878.25 348.666L1883.8 355.544C1894.48 368.781 1904.27 378.886 1913.18 385.857L1876.87 415.135C1871.95 404.95 1864.15 393.239 1853.47 380.002L1843.63 367.799C1832.95 354.562 1823.16 344.457 1814.25 337.485L1850.56 308.207C1855.43 318.319 1862.63 329.29 1872.17 341.123L1872.44 341.456L1875.53 338.956C1879.16 336.041 1880.67 332.56 1880.08 328.514C1879.44 324.395 1876.85 319.525 1872.32 313.905C1868.33 308.95 1863.25 305.295 1857.09 302.941L1891.18 275.448C1896.99 278.574 1901.98 282.725 1906.15 287.902C1912.36 295.593 1914.3 302.384 1911.99 308.277C1909.62 314.094 1902.6 321.705 1890.95 331.107L1882.97 337.534L1884.14 338.976L1884.47 338.708C1887.28 336.447 1891.86 334.21 1898.24 331.998C1904.69 329.726 1909.68 327.162 1913.22 324.305C1920.98 318.057 1923.68 310.385 1921.33 301.29L1953.09 340.669C1954.15 344.091 1953.98 347.953 1952.58 352.256C1951.19 356.426 1948.98 359.731 1945.95 362.171Z"
        fill="#BCD7C4"
      />
      <path
        d="M1138.74 1189.74C1134.47 1193.19 1130.22 1195.33 1126.02 1196.15C1121.89 1196.92 1118.14 1196.65 1114.77 1195.34C1111.4 1194.03 1108.11 1192.22 1104.92 1189.92C1101.66 1187.55 1098.62 1185.05 1095.79 1182.45C1092.98 1179.72 1090.29 1177.37 1087.73 1175.4C1085.17 1173.44 1082.6 1172.22 1080.02 1171.73C1077.51 1171.19 1075.16 1171.82 1072.94 1173.6C1072.06 1174.32 1071.43 1175.2 1071.04 1176.23L1076.59 1183.11C1087.26 1196.35 1097.06 1206.45 1105.97 1213.42L1069.66 1242.7C1064.74 1232.51 1056.94 1220.8 1046.26 1207.57L1036.42 1195.37C1025.74 1182.13 1015.95 1172.02 1007.04 1165.05L1043.35 1135.78C1048.21 1145.89 1055.42 1156.85 1064.96 1168.69L1065.23 1169.02L1068.33 1166.52C1071.94 1163.61 1073.46 1160.13 1072.87 1156.08C1072.23 1151.96 1069.65 1147.09 1065.11 1141.47C1061.11 1136.52 1056.04 1132.86 1049.87 1130.51L1083.97 1103.02C1089.78 1106.14 1094.77 1110.29 1098.94 1115.47C1105.14 1123.16 1107.09 1129.95 1104.78 1135.84C1102.41 1141.66 1095.4 1149.27 1083.73 1158.67L1075.77 1165.1L1076.93 1166.54L1077.26 1166.28C1080.06 1164.01 1084.65 1161.78 1091.03 1159.56C1097.48 1157.29 1102.47 1154.73 1106.01 1151.87C1113.76 1145.62 1116.47 1137.95 1114.12 1128.86L1145.88 1168.23C1146.94 1171.65 1146.76 1175.52 1145.36 1179.82C1143.97 1183.99 1141.77 1187.29 1138.74 1189.74Z"
        fill="#BCD7C4"
      />
      <path
        d="M1158.19 1171.31C1153.26 1161.12 1145.47 1149.41 1134.79 1136.17L1124.95 1123.97C1114.27 1110.73 1104.48 1100.63 1095.57 1093.66L1131.87 1064.38C1136.8 1074.56 1144.6 1086.28 1155.28 1099.51L1165.11 1111.71C1175.79 1124.95 1185.59 1135.06 1194.49 1142.03L1158.19 1171.31Z"
        fill="#BCD7C4"
      />
      <path
        d="M1222.17 1119.71C1217.25 1109.52 1209.44 1097.81 1198.77 1084.58L1188.93 1072.37C1178.26 1059.13 1168.46 1049.03 1159.55 1042.06L1195.85 1012.78C1200.78 1022.97 1208.58 1034.68 1219.25 1047.91L1229.1 1060.12C1239.77 1073.36 1249.56 1083.46 1258.48 1090.43L1222.17 1119.71Z"
        fill="#BCD7C4"
      />
      <path
        d="M1337.3 1029.61C1333.02 1033.06 1328.78 1035.2 1324.57 1036.03C1320.44 1036.8 1316.69 1036.53 1313.32 1035.21C1309.95 1033.9 1306.67 1032.09 1303.47 1029.79C1300.21 1027.42 1297.17 1024.92 1294.34 1022.33C1291.53 1019.59 1288.84 1017.24 1286.28 1015.28C1283.72 1013.31 1281.16 1012.09 1278.57 1011.61C1276.07 1011.06 1273.71 1011.69 1271.5 1013.48C1270.61 1014.19 1269.97 1015.07 1269.59 1016.1L1275.14 1022.98C1285.82 1036.22 1295.61 1046.32 1304.52 1053.3L1268.22 1082.58C1263.29 1072.39 1255.49 1060.67 1244.81 1047.44L1234.97 1035.24C1224.3 1022 1214.51 1011.89 1205.6 1004.93L1241.9 975.647C1246.77 985.756 1253.97 996.725 1263.51 1008.56L1263.78 1008.89L1266.88 1006.39C1270.5 1003.48 1272.01 999.997 1271.43 995.952C1270.78 991.833 1268.2 986.966 1263.66 981.343C1259.67 976.389 1254.59 972.731 1248.43 970.38L1282.52 942.887C1288.33 946.011 1293.32 950.161 1297.5 955.341C1303.7 963.033 1305.64 969.821 1303.34 975.714C1300.96 981.533 1293.95 989.145 1282.29 998.542L1274.32 1004.97L1275.48 1006.41L1275.81 1006.15C1278.62 1003.88 1283.21 1001.65 1289.59 999.439C1296.03 997.161 1301.03 994.602 1304.57 991.741C1312.32 985.492 1315.02 977.826 1312.68 968.729L1344.44 1008.11C1345.49 1011.53 1345.31 1015.39 1343.92 1019.69C1342.53 1023.86 1340.32 1027.17 1337.3 1029.61Z"
        fill="#BCD7C4"
      />
      <path
        d="M1356.75 1011.18C1351.82 1001 1344.02 989.286 1333.34 976.046L1323.5 963.843C1312.83 950.609 1303.04 940.505 1294.12 933.532L1330.43 904.252C1335.36 914.436 1343.16 926.148 1353.83 939.388L1363.67 951.591C1374.35 964.825 1384.14 974.928 1393.05 981.902L1356.75 1011.18Z"
        fill="#BCD7C4"
      />
      <path
        d="M1420.72 959.583C1415.8 949.393 1408 937.682 1397.33 924.448L1387.49 912.245C1376.8 899.004 1367.01 888.901 1358.1 881.934L1394.41 852.654C1399.33 862.838 1407.13 874.55 1417.81 887.784L1427.65 899.986C1438.33 913.227 1448.12 923.33 1457.03 930.303L1420.72 959.583Z"
        fill="#BCD7C4"
      />
      <path
        d="M1535.86 869.479C1531.58 872.929 1527.34 875.071 1523.13 875.9C1519 876.667 1515.25 876.397 1511.88 875.083C1508.51 873.776 1505.22 871.971 1502.03 869.663C1498.77 867.288 1495.73 864.802 1492.9 862.199C1490.08 859.462 1487.4 857.117 1484.84 855.153C1482.28 853.188 1479.71 851.961 1477.13 851.482C1474.63 850.942 1472.26 851.562 1470.05 853.348C1469.16 854.06 1468.53 854.938 1468.15 855.975L1473.7 862.856C1484.37 876.09 1494.17 886.194 1503.08 893.167L1466.77 922.446C1461.85 912.263 1454.04 900.551 1443.37 887.311L1433.53 875.108C1422.86 861.874 1413.06 851.77 1404.15 844.797L1440.45 815.518C1445.32 825.627 1452.53 836.603 1462.07 848.431L1462.34 848.769L1465.44 846.264C1469.05 843.349 1470.57 839.868 1469.99 835.823C1469.34 831.704 1466.76 826.837 1462.22 821.214C1458.22 816.26 1453.15 812.608 1446.99 810.251L1481.08 782.758C1486.88 785.882 1491.88 790.038 1496.05 795.212C1502.25 802.904 1504.2 809.693 1501.89 815.585C1499.52 821.404 1492.5 829.016 1480.84 838.42L1472.88 844.846L1474.04 846.289L1474.37 846.019C1477.17 843.76 1481.76 841.519 1488.14 839.31C1494.59 837.038 1499.58 834.473 1503.13 831.618C1510.87 825.37 1513.57 817.697 1511.23 808.6L1542.99 847.977C1544.04 851.402 1543.87 855.263 1542.47 859.566C1541.08 863.734 1538.88 867.042 1535.86 869.479Z"
        fill="#BCD7C4"
      />
      <path
        d="M1555.3 851.052C1550.37 840.869 1542.57 829.157 1531.9 815.917L1522.06 803.714C1511.38 790.48 1501.58 780.376 1492.68 773.403L1528.98 744.124C1533.91 754.307 1541.71 766.019 1552.38 779.259L1562.22 791.462C1572.9 804.696 1582.69 814.8 1591.6 821.773L1555.3 851.052Z"
        fill="#BCD7C4"
      />
      <path
        d="M1619.28 799.454C1614.36 789.27 1606.55 777.559 1595.88 764.318L1586.03 752.115C1575.36 738.881 1565.57 728.778 1556.66 721.805L1592.96 692.525C1597.89 702.709 1605.69 714.42 1616.36 727.661L1626.2 739.863C1636.88 753.098 1646.67 763.201 1655.59 770.174L1619.28 799.454Z"
        fill="#BCD7C4"
      />
      <path
        d="M1734.41 709.35C1730.12 712.806 1725.88 714.942 1721.68 715.771C1717.55 716.538 1713.8 716.268 1710.43 714.961C1707.06 713.647 1703.78 711.842 1700.58 709.54C1697.32 707.159 1694.28 704.673 1691.45 702.07C1688.63 699.339 1685.95 696.988 1683.39 695.023C1680.83 693.059 1678.26 691.838 1675.68 691.353C1673.18 690.813 1670.82 691.433 1668.6 693.219C1667.72 693.931 1667.08 694.809 1666.7 695.846L1672.25 702.727C1682.93 715.961 1692.72 726.071 1701.63 733.038L1665.33 762.317C1660.4 752.134 1652.6 740.422 1641.92 727.182L1632.08 714.985C1621.41 701.745 1611.61 691.641 1602.71 684.668L1639.01 655.389C1643.87 665.505 1651.08 676.474 1660.62 688.308L1660.89 688.64L1663.99 686.141C1667.61 683.226 1669.12 679.745 1668.54 675.694C1667.89 671.575 1665.3 666.708 1660.77 661.085C1656.78 656.131 1651.7 652.479 1645.54 650.122L1679.63 622.629C1685.44 625.759 1690.43 629.909 1694.61 635.083C1700.81 642.775 1702.75 649.57 1700.44 655.462C1698.07 661.275 1691.05 668.887 1679.4 678.291L1671.43 684.717L1672.59 686.16L1672.92 685.89C1675.73 683.631 1680.32 681.39 1686.69 679.181C1693.14 676.909 1698.14 674.344 1701.68 671.489C1709.42 665.241 1712.13 657.568 1709.79 648.471L1741.55 687.854C1742.6 691.273 1742.42 695.134 1741.02 699.437C1739.64 703.611 1737.43 706.913 1734.41 709.35Z"
        fill="#BCD7C4"
      />
      <path
        d="M1753.85 690.923C1748.92 680.74 1741.12 669.028 1730.45 655.794L1720.61 643.591C1709.93 630.351 1700.14 620.247 1691.23 613.275L1727.53 583.997C1732.46 594.182 1740.26 605.894 1750.94 619.13L1760.78 631.333C1771.45 644.573 1781.24 654.677 1790.15 661.65L1753.85 690.923Z"
        fill="#BCD7C4"
      />
      <path
        d="M1817.83 639.325C1812.9 629.142 1805.11 617.43 1794.43 604.191L1784.59 591.99C1773.91 578.752 1764.12 568.648 1755.21 561.676L1791.52 532.398C1796.44 542.583 1804.24 554.294 1814.92 567.531L1824.76 579.733C1835.43 592.971 1845.23 603.075 1854.14 610.047L1817.83 639.325Z"
        fill="#BCD7C4"
      />
      <path
        d="M1932.96 549.226C1928.68 552.677 1924.44 554.816 1920.23 555.644C1916.11 556.412 1912.36 556.141 1908.99 554.831C1905.62 553.521 1902.33 551.715 1899.14 549.411C1895.88 547.034 1892.83 544.545 1890.01 541.943C1887.19 539.209 1884.51 536.86 1881.95 534.895C1879.39 532.931 1876.82 531.708 1874.24 531.226C1871.74 530.684 1869.37 531.307 1867.16 533.092C1866.27 533.806 1865.64 534.682 1865.26 535.722L1870.81 542.599C1881.48 555.836 1891.27 565.941 1900.19 572.913L1863.88 602.191C1858.96 592.006 1851.15 580.295 1840.48 567.057L1830.64 554.855C1819.97 541.617 1810.17 531.513 1801.26 524.541L1837.56 495.263C1842.43 505.374 1849.63 516.346 1859.18 528.178L1859.45 528.511L1862.55 526.011C1866.16 523.096 1867.68 519.615 1867.09 515.57C1866.45 511.45 1863.86 506.58 1859.33 500.96C1855.33 496.005 1850.26 492.35 1844.09 489.996L1878.19 462.503C1883.99 465.629 1888.98 469.781 1893.16 474.958C1899.36 482.648 1901.31 489.44 1899 495.332C1896.63 501.15 1889.61 508.76 1877.95 518.162L1869.99 524.589L1871.15 526.032L1871.48 525.763C1874.28 523.502 1878.87 521.265 1885.25 519.053C1891.7 516.781 1896.69 514.217 1900.24 511.361C1907.98 505.112 1910.68 497.441 1908.34 488.345L1940.1 527.725C1941.15 531.146 1940.98 535.008 1939.58 539.311C1938.19 543.481 1935.98 546.786 1932.96 549.226Z"
        fill="#BCD7C4"
      />
      <path
        d="M1343.75 1198.24C1338.83 1188.05 1331.03 1176.34 1320.35 1163.1L1310.51 1150.9C1299.84 1137.66 1290.04 1127.56 1281.13 1120.58L1317.44 1091.31C1322.36 1101.49 1330.16 1113.2 1340.84 1126.44L1350.68 1138.64C1361.36 1151.88 1371.15 1161.99 1380.06 1168.96L1343.75 1198.24Z"
        fill="#BCD7C4"
      />
      <path
        d="M1407.74 1146.63C1402.81 1136.45 1395.01 1124.74 1384.33 1111.5L1374.49 1099.3C1363.82 1086.06 1354.03 1075.96 1345.12 1068.99L1381.42 1039.71C1386.35 1049.9 1394.15 1061.61 1404.82 1074.84L1414.66 1087.04C1425.34 1100.28 1435.13 1110.39 1444.04 1117.35L1407.74 1146.63Z"
        fill="#BCD7C4"
      />
      <path
        d="M1522.86 1056.54C1518.58 1059.99 1514.34 1062.13 1510.13 1062.95C1506 1063.72 1502.25 1063.45 1498.88 1062.14C1495.51 1060.83 1492.23 1059.02 1489.04 1056.72C1485.78 1054.35 1482.73 1051.85 1479.9 1049.25C1477.09 1046.52 1474.4 1044.17 1471.85 1042.2C1469.29 1040.24 1466.72 1039.02 1464.14 1038.53C1461.63 1037.99 1459.27 1038.62 1457.06 1040.4C1456.17 1041.12 1455.54 1042 1455.16 1043.03L1460.71 1049.91C1471.38 1063.15 1481.17 1073.25 1490.09 1080.22L1453.78 1109.5C1448.86 1099.31 1441.05 1087.6 1430.38 1074.37L1420.54 1062.17C1409.86 1048.93 1400.07 1038.82 1391.16 1031.85L1427.46 1002.58C1432.33 1012.68 1439.53 1023.65 1449.08 1035.49L1449.35 1035.82L1452.45 1033.32C1456.06 1030.41 1457.58 1026.93 1457 1022.88C1456.35 1018.76 1453.76 1013.89 1449.23 1008.27C1445.23 1003.32 1440.15 999.659 1434 997.309L1468.09 969.815C1473.89 972.94 1478.88 977.089 1483.06 982.27C1489.26 989.961 1491.21 996.75 1488.9 1002.64C1486.53 1008.46 1479.51 1016.07 1467.85 1025.47L1459.88 1031.9L1461.05 1033.34L1461.38 1033.08C1464.18 1030.81 1468.77 1028.58 1475.15 1026.36C1481.6 1024.09 1486.59 1021.53 1490.14 1018.67C1497.88 1012.42 1500.58 1004.75 1498.24 995.657L1530 1035.03C1531.05 1038.45 1530.88 1042.32 1529.48 1046.62C1528.09 1050.79 1525.88 1054.09 1522.86 1056.54Z"
        fill="#BCD7C4"
      />
      <path
        d="M1542.31 1038.11C1537.38 1027.92 1529.58 1016.21 1518.91 1002.97L1509.07 990.771C1498.39 977.537 1488.6 967.428 1479.68 960.461L1515.99 931.181C1520.91 941.365 1528.72 953.076 1539.39 966.317L1549.23 978.513C1559.91 991.754 1569.7 1001.86 1578.61 1008.83L1542.31 1038.11Z"
        fill="#BCD7C4"
      />
      <path
        d="M1606.29 986.505C1601.36 976.322 1593.56 964.61 1582.89 951.376L1573.05 939.173C1562.37 925.933 1552.57 915.829 1543.67 908.856L1579.97 879.583C1584.9 889.766 1592.7 901.478 1603.37 914.712L1613.21 926.915C1623.89 940.155 1633.68 950.259 1642.59 957.232L1606.29 986.505Z"
        fill="#BCD7C4"
      />
      <path
        d="M1721.41 896.408C1717.14 899.857 1712.89 902 1708.69 902.828C1704.56 903.596 1700.81 903.326 1697.44 902.012C1694.07 900.705 1690.78 898.9 1687.59 896.592C1684.33 894.216 1681.29 891.724 1678.46 889.128C1675.65 886.39 1672.96 884.045 1670.4 882.075C1667.84 880.111 1665.27 878.889 1662.69 878.41C1660.18 877.87 1657.83 878.49 1655.61 880.276C1654.73 880.988 1654.1 881.866 1653.71 882.904L1659.26 889.778C1669.94 903.019 1679.73 913.122 1688.64 920.095L1652.33 949.375C1647.41 939.185 1639.61 927.48 1628.93 914.239L1619.09 902.037C1608.41 888.802 1598.62 878.693 1589.71 871.726L1626.02 842.446C1630.89 852.556 1638.09 863.531 1647.63 875.36L1647.9 875.691L1651 873.193C1654.61 870.277 1656.13 866.797 1655.54 862.752C1654.91 858.633 1652.32 853.765 1647.78 848.143C1643.79 843.189 1638.71 839.531 1632.55 837.18L1666.64 809.686C1672.45 812.811 1677.44 816.966 1681.61 822.141C1687.82 829.832 1689.76 836.621 1687.45 842.514C1685.08 848.333 1678.07 855.944 1666.41 865.348L1658.43 871.775L1659.6 873.211L1659.93 872.947C1662.74 870.682 1667.32 868.448 1673.7 866.238C1680.15 863.967 1685.14 861.401 1688.68 858.541C1696.44 852.298 1699.14 844.625 1696.79 835.528L1728.55 874.905C1729.61 878.331 1729.44 882.191 1728.03 886.494C1726.65 890.662 1724.44 893.971 1721.41 896.408Z"
        fill="#BCD7C4"
      />
      <path
        d="M1740.86 877.981C1735.94 867.797 1728.14 856.085 1717.46 842.845L1707.62 830.642C1696.94 817.408 1687.15 807.305 1678.24 800.331L1714.55 771.052C1719.47 781.235 1727.27 792.947 1737.95 806.187L1747.78 818.39C1758.47 831.624 1768.26 841.728 1777.17 848.701L1740.86 877.981Z"
        fill="#BCD7C4"
      />
      <path
        d="M1804.85 826.382C1799.92 816.193 1792.11 804.487 1781.44 791.247L1771.6 779.044C1760.93 765.81 1751.13 755.7 1742.22 748.733L1778.52 719.454C1783.45 729.637 1791.25 741.349 1801.93 754.589L1811.77 766.786C1822.44 780.026 1832.23 790.13 1841.15 797.103L1804.85 826.382Z"
        fill="#BCD7C4"
      />
      <path
        d="M1919.97 736.279C1915.69 739.735 1911.45 741.871 1907.24 742.699C1903.11 743.467 1899.36 743.197 1895.99 741.889C1892.62 740.576 1889.34 738.771 1886.14 736.469C1882.89 734.087 1879.84 731.601 1877.01 728.999C1874.2 726.267 1871.51 723.916 1868.95 721.952C1866.4 719.988 1863.83 718.76 1861.25 718.281C1858.74 717.741 1856.38 718.361 1854.17 720.147C1853.28 720.859 1852.65 721.737 1852.27 722.775L1857.81 729.656C1868.49 742.89 1878.28 752.993 1887.2 759.966L1850.89 789.246C1845.97 779.063 1838.16 767.351 1827.49 754.111L1817.64 741.908C1806.97 728.674 1797.18 718.57 1788.27 711.597L1824.57 682.317C1829.43 692.427 1836.64 703.402 1846.19 715.231L1846.45 715.568L1849.55 713.064C1853.17 710.148 1854.68 706.668 1854.1 702.623C1853.46 698.504 1850.87 693.636 1846.34 688.014C1842.34 683.06 1837.26 679.408 1831.1 677.051L1865.19 649.557C1871 652.682 1875.99 656.837 1880.17 662.012C1886.37 669.703 1888.32 676.498 1886.01 682.385C1883.64 688.204 1876.62 695.815 1864.96 705.219L1856.99 711.646L1858.16 713.088L1858.49 712.818C1861.29 710.559 1865.88 708.319 1872.26 706.109C1878.7 703.838 1883.7 701.272 1887.24 698.418C1894.99 692.169 1897.69 684.496 1895.35 675.4L1927.11 714.783C1928.16 718.202 1927.99 722.063 1926.59 726.365C1925.2 730.533 1922.99 733.842 1919.97 736.279Z"
        fill="#BCD7C4"
      />
      <path
        d="M1593.3 1173.56C1588.37 1163.38 1580.57 1151.67 1569.89 1138.43L1560.05 1126.22C1549.38 1112.99 1539.59 1102.89 1530.67 1095.91L1566.98 1066.63C1571.9 1076.82 1579.71 1088.53 1590.38 1101.77L1600.22 1113.97C1610.89 1127.21 1620.69 1137.32 1629.6 1144.28L1593.3 1173.56Z"
        fill="#BCD7C4"
      />
      <path
        d="M1708.42 1083.47C1704.14 1086.92 1699.9 1089.06 1695.69 1089.88C1691.56 1090.65 1687.82 1090.38 1684.44 1089.07C1681.07 1087.76 1677.79 1085.95 1674.6 1083.65C1671.34 1081.27 1668.29 1078.78 1665.47 1076.18C1662.65 1073.45 1659.96 1071.1 1657.41 1069.13C1654.85 1067.17 1652.28 1065.95 1649.7 1065.46C1647.19 1064.92 1644.83 1065.54 1642.62 1067.33C1641.73 1068.05 1641.1 1068.92 1640.72 1069.96L1646.27 1076.84C1656.94 1090.08 1666.73 1100.18 1675.65 1107.15L1639.34 1136.43C1634.42 1126.24 1626.61 1114.53 1615.94 1101.3L1606.1 1089.09C1595.43 1075.85 1585.63 1065.75 1576.72 1058.78L1613.02 1029.5C1617.89 1039.61 1625.09 1050.58 1634.64 1062.42L1634.91 1062.75L1638.01 1060.25C1641.62 1057.33 1643.14 1053.85 1642.56 1049.81C1641.91 1045.69 1639.32 1040.82 1634.79 1035.2C1630.79 1030.24 1625.72 1026.59 1619.56 1024.23L1653.65 996.744C1659.45 999.868 1664.44 1004.02 1668.62 1009.19C1674.82 1016.88 1676.77 1023.68 1674.46 1029.57C1672.09 1035.39 1665.07 1043 1653.41 1052.4L1645.44 1058.83L1646.61 1060.27L1646.94 1060C1649.74 1057.74 1654.33 1055.51 1660.71 1053.29C1667.16 1051.02 1672.15 1048.45 1675.7 1045.6C1683.44 1039.35 1686.14 1031.68 1683.8 1022.59L1715.56 1061.96C1716.61 1065.38 1716.44 1069.25 1715.04 1073.55C1713.66 1077.72 1711.45 1081.02 1708.42 1083.47Z"
        fill="#BCD7C4"
      />
      <path
        d="M1727.86 1065.04C1722.94 1054.85 1715.14 1043.14 1704.47 1029.9L1694.63 1017.7C1683.95 1004.46 1674.15 994.356 1665.24 987.383L1701.55 958.11C1706.47 968.293 1714.27 980.005 1724.95 993.239L1734.79 1005.44C1745.47 1018.68 1755.26 1028.79 1764.17 1035.76L1727.86 1065.04Z"
        fill="#BCD7C4"
      />
      <path
        d="M1791.85 1013.43C1786.92 1003.25 1779.12 991.539 1768.45 978.305L1758.61 966.102C1747.93 952.862 1738.14 942.758 1729.23 935.785L1765.53 906.505C1770.46 916.695 1778.26 928.407 1788.94 941.641L1798.78 953.844C1809.45 967.084 1819.25 977.187 1828.15 984.161L1791.85 1013.43Z"
        fill="#BCD7C4"
      />
      <path
        d="M1906.97 923.336C1902.69 926.786 1898.45 928.928 1894.25 929.757C1890.12 930.524 1886.37 930.254 1883 928.941C1879.63 927.633 1876.34 925.822 1873.14 923.521C1869.89 921.145 1866.85 918.653 1864.02 916.056C1861.21 913.319 1858.52 910.968 1855.96 909.004C1853.4 907.039 1850.83 905.818 1848.25 905.339C1845.74 904.793 1843.39 905.419 1841.17 907.205C1840.29 907.917 1839.65 908.795 1839.27 909.832L1844.82 916.707C1855.5 929.947 1865.29 940.051 1874.2 947.024L1837.89 976.304C1832.97 966.114 1825.17 954.402 1814.49 941.168L1804.65 928.965C1793.97 915.725 1784.18 905.621 1775.27 898.654L1811.58 869.375C1816.45 879.485 1823.65 890.454 1833.19 902.288L1833.46 902.62L1836.55 900.122C1840.18 897.206 1841.69 893.725 1841.1 889.68C1840.46 885.562 1837.87 880.688 1833.34 875.071C1829.35 870.118 1824.27 866.459 1818.11 864.108L1852.2 836.615C1858.01 839.739 1863 843.889 1867.17 849.07C1873.38 856.761 1875.32 863.55 1873.01 869.442C1870.64 875.262 1863.62 882.873 1851.97 892.271L1843.99 898.697L1845.16 900.14L1845.49 899.876C1848.3 897.611 1852.88 895.377 1859.26 893.167C1865.71 890.89 1870.7 888.33 1874.24 885.47C1882 879.221 1884.7 871.554 1882.35 862.457L1914.11 901.834C1915.17 905.259 1914.99 909.12 1913.6 913.423C1912.21 917.591 1910 920.893 1906.97 923.336Z"
        fill="#BCD7C4"
      />
      <path
        d="M1778.86 1200.49C1773.93 1190.31 1766.13 1178.6 1755.46 1165.36L1745.62 1153.15C1734.94 1139.92 1725.15 1129.82 1716.23 1122.84L1752.54 1093.56C1757.46 1103.75 1765.26 1115.46 1775.94 1128.7L1785.79 1140.9C1796.46 1154.14 1806.25 1164.24 1815.16 1171.21L1778.86 1200.49Z"
        fill="#BCD7C4"
      />
      <path
        d="M1893.99 1110.39C1889.71 1113.84 1885.47 1115.98 1881.26 1116.81C1877.13 1117.58 1873.38 1117.31 1870.01 1116C1866.64 1114.68 1863.36 1112.88 1860.16 1110.58C1856.9 1108.2 1853.86 1105.71 1851.03 1103.11C1848.22 1100.38 1845.53 1098.03 1842.97 1096.06C1840.41 1094.1 1837.85 1092.88 1835.26 1092.39C1832.76 1091.85 1830.39 1092.47 1828.18 1094.26C1827.3 1094.97 1826.66 1095.85 1826.28 1096.89L1831.83 1103.76C1842.5 1117 1852.3 1127.11 1861.21 1134.08L1824.91 1163.35C1819.98 1153.17 1812.18 1141.46 1801.5 1128.22L1791.66 1116.02C1780.99 1102.78 1771.2 1092.68 1762.28 1085.71L1798.58 1056.43C1803.45 1066.54 1810.66 1077.51 1820.2 1089.35L1820.47 1089.68L1823.57 1087.18C1827.18 1084.26 1828.7 1080.78 1828.12 1076.74C1827.47 1072.61 1824.89 1067.75 1820.35 1062.12C1816.36 1057.17 1811.28 1053.52 1805.12 1051.16L1839.21 1023.67C1845.02 1026.8 1850.01 1030.95 1854.19 1036.12C1860.38 1043.81 1862.33 1050.61 1860.02 1056.5C1857.65 1062.31 1850.64 1069.92 1838.97 1079.33L1831.01 1085.76L1832.17 1087.2L1832.5 1086.93C1835.3 1084.67 1839.9 1082.43 1846.27 1080.22C1852.72 1077.95 1857.71 1075.38 1861.26 1072.53C1869 1066.28 1871.71 1058.61 1869.37 1049.51L1901.12 1088.89C1902.18 1092.31 1902 1096.17 1900.6 1100.47C1899.22 1104.65 1897.01 1107.95 1893.99 1110.39Z"
        fill="#BCD7C4"
      />
    </g>
    <defs>
      <clipPath id="clip0_182_1348">
        <rect width="2319" height="2051" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
